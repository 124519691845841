@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































































































































@import '@scss';

.donutSkeleton {
  margin: auto;
  border: 13px solid $color-primary-glass;
  border-right-color: $color-accent-glass;
  border-bottom-color: $color-mid-primary-glass;
  border-radius: 50%;
  animation-direction: reverse;
}

.bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  padding: 25px;
  font-weight: 500;
  color: $color-white;
  text-align: center;
  background: $gradient-primary-diagonal;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  border-radius: 0 0 $size-card-border-radius $size-card-border-radius;

  a {
    color: $color-white !important;
  }

  @include mq($from: wide) {
    padding: 25px 90px;
  }

  @include mq($until: mobile) {
    padding: 12px;
    font-size: 14px;
  }
}
