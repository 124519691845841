@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























































@import '@scss';

.link {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 18px;
  margin: 0 -20px;
  font-size: 17px;
  color: $color-dark-grey !important;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: $color-primary !important;
    background-color: $color-mid-ice;
  }

  > :global(.fp-icon) {
    min-width: 17px;
    margin-right: 14px;
  }

  + .action {
    margin-top: 5px;
  }
}
