@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































































@import '@scss';

.actions {
  .listItemTitle {
    color: $color-mid-grey;
  }
}
