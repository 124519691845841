@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.container {
  position: relative;
  z-index: 2;
  flex-grow: 0;
  margin: 0 25px;
}

.filters {
  margin: 0 -12px;
}

.title {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  line-height: 36px;
  color: $color-white;
  white-space: nowrap;

  // @include mq($from: sm) {
  //   // fix uneven vertical align with small button, e.g. filters
  //   padding-bottom: 4.5px;
  // }
}

.subtitle {
  display: block;
  max-width: 440px;
  font-size: 14px;
  line-height: 19px;
  color: $color-white;

  @include mq-height($until: skinny) {
    max-width: 380px;
    font-size: 12px;
    line-height: 15px;
  }
}

.moreFilters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5vw;
  margin-top: -5vw;
  color: $color-white;
  user-select: none;
}
