@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(radio) {
  @include perfect-transition;

  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: $size-radio-min-height-normal;
  padding: 10px 17px 13px;
  color: $color-mid-grey;
  background-color: $color-white;
  border: 2px solid $color-dark-ice;
  border-radius: 6px;
  box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12), 0 0 0 1px transparent,
    0 0 0 5px transparent;

  & + & {
    margin-left: 15px;
  }

  a {
    color: $color-mid-primary;
  }

  @include pseudo-class(hover focus) {
    &:not(.is-disabled):not(.is-checked) {
      cursor: pointer;
      border-color: $color-mid-primary;
      box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12),
        0 0 0 1px $color-mid-primary, 0 0 0 5px $color-primary-glass;

      @include element(indicator) {
        border-color: $color-mid-primary;
      }
    }

    outline: none;
  }

  // ===
  // Elements
  // ===

  @include element(disabled-text) {
    position: absolute;
    bottom: -#{$size-input-indicator / 2};
    background: $color-disabled;
    border-radius: 4px;

    @include mq($until: md) {
      .v-chip__content {
        font-size: 12px;
      }
    }

    @include mq($until: mobile) {
      .v-chip__content {
        font-size: 10px;
      }
    }
  }

  @include element(label) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.3;
    text-align: center;
  }

  @include element(indicator 'ban-icon') {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: txy(-50%, 50%);
  }

  @include element(indicator) {
    @include perfect-transition;
    @include circle($size-input-indicator, $color-white);

    align-items: center;
    justify-content: center;
    border: 6px solid $color-dark-ice;

    @include pseudo-element(after) {
      @include circle(12px, $color-white);

      content: '';
    }
  }

  // ===
  // States
  // ===

  @include when(checked) {
    color: $color-mid-primary;
    border-color: $color-mid-primary;
    box-shadow: 0 4px 24px transparent, 0 0 0 1px $color-mid-primary,
      0 0 0 5px $color-primary-glass;

    @include element(indicator) {
      display: flex;
      border-color: $color-mid-primary;
    }
  }

  @include when(disabledisStr) {
    @include element(label) {
      padding: 20px;
    }
  }

  @include when(disabled) {
    cursor: not-allowed;
    border-color: $color-disabled;
    box-shadow: none;

    @include when(checked) {
      opacity: 0.8;
    }
  }

  @include when(button) {
    min-height: $size-input-height-normal;
    padding: 0;

    & + & {
      margin-left: 0;
    }

    @include when(checked) {
      color: $color-white;
      cursor: default;
      background-color: $color-primary;
      box-shadow: none;
    }

    @include element(indicator) {
      display: none;
    }
  }
}

@include block('radio-group') {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;

  @include element(label) {
    @extend %font-label;
  }

  @include element(hint) {
    @extend %font-label-hint;
  }

  @include element('below-root') {
    width: 100%;
  }

  @include element(message) {
    @extend %typography-small;

    padding: 0 12px;
    margin-top: 10px;
    font-size: 12px;
  }

  @include when(error) {
    @include element(message) {
      color: $color-error;
    }

    @include element(label) {
      color: $color-error;
    }

    @include block(radio) {
      border: 2px solid $color-error;

      @include element(indicator) {
        border: 2px solid $color-error;
      }

      @include element(label) {
        color: $color-error;
      }
    }
  }
}
