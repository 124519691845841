@import '@scss';

// V-card
// element overrides
.v-application {
  font-family: $content-font-family;

  .primary--text {
    color: $color-mid-primary !important;
  }

  .error--text {
    color: $color-error !important;
  }
}

.theme--light {
  &.v-time-picker-clock {
    background-color: $color-mid-ice;
  }
}

.v-card {
  padding: 0;

  &:not(.v-select-list):not(.v-picker) {
    @include card-border();
  }

  &.v-picker {
    box-shadow: none;
  }

  &__title {
    @extend %typography-large;

    padding-top: 10px;
    font-weight: bold;
  }
}

.v-data-table__empty-wrapper {
  pointer-events: initial;
}

.v-picker__title {
  width: 280px;
  padding: 20px 30px;
  background-image: $gradient-primary;
}

.v-time-picker-clock__container {
  padding: 30px 20px 30px 40px;
}

.v-time-picker-title {
  &__time {
    span,
    .v-picker__title__btn {
      height: $size-input-height-normal;
      font-size: $size-input-height-normal;
    }
  }
}

.v-dialog {
  @include mq($until: mobile) {
    margin: unset;
  }
}

.v-avatar {
  font-family: $heading-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.v-tooltip__content {
  max-width: 500px;
  text-align: center;
  word-break: break-word;
  strong {
    text-overflow: unset;
    white-space: unset;
  }
}
