@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@function button-shadow-color($color) {
  @return rgba(darken($color, 10%), 0.7);
}

@include block(button) {
  --min-width: 170px;
  --height: #{$size-button-height-normal};
  --radiation-size: 5px;
  --radiation-color: #{$color-primary-glass};
  --text-color: #{$color-white};
  --icon-color: #{$color-white};
  --icon-background: #{rgba($color-white, 0.25)};
  --background: linear-gradient(180deg, #57a3fc 0%, #0070f3 100%);
  --shadow-color: #{button-shadow-color($color-primary)};

  @extend %font-button;

  @include perfect-transition;

  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  min-width: var(--min-width);
  height: var(--height);
  padding: 0 $size-button-padding-normal;
  line-height: 1;
  color: var(--text-color);
  cursor: pointer;
  user-select: none;
  background: var(--background);
  border-radius: 150px;
  box-shadow: 0 6px 15px -8px var(--shadow-color);

  & + & {
    margin-left: 12px;
  }

  // Radiation
  @include pseudo-element(before) {
    @include perfect-transition;

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: inherit;
    box-shadow: 0 0 0 0 var(--radiation-color);
  }

  @include pseudo-class(hover) {
    outline: none;

    &::before {
      box-shadow: 0 0 0 calc(var(--radiation-size)) var(--radiation-color);
    }
  }

  @include pseudo-class(focus) {
    outline: none;
    box-shadow: 0 10px 15px -12px var(--shadow-color);

    &::before {
      box-shadow: 0 0 0 calc(var(--radiation-size) * 1.5) var(--radiation-color);
    }
  }

  // ===
  // Elements
  // ===

  @include element(text) {
    flex-grow: 1;
    order: 2;
    padding: 0 20px;
    margin-top: 0;
    margin-right: -10px;
    margin-left: -5px;
    font-size: 1em;
    line-height: inherit;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: $content-letter-spacing;
    white-space: nowrap;

    @include emphasize {
      margin-bottom: 0;
    }

    @include modifier(inherit) {
      color: inherit;
    }

    @include modifier(primary) {
      color: $color-mid-primary;
    }

    @include modifier(error) {
      color: $color-error;
    }

    @include modifier(success) {
      color: $color-success;
    }

    @include modifier(warning) {
      color: $color-warning;
    }

    @include modifier(accent) {
      color: $color-accent;
    }
  }

  @include element(icon) {
    @include circle($size-button-icon-diameter-normal, var(--icon-background));
    @include perfect-transition;

    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    order: 3;
    font-size: 0.8em;
    color: var(--icon-color);

    @include modifier(left) {
      order: 1;
    }
  }

  @include element(loader) {
    --spinner-size: 24px;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    background: inherit;
    border-radius: inherit;

    > .v-progress-circular {
      min-width: var(--spinner-size);
      max-width: var(--spinner-size);
      min-height: var(--spinner-size);
      max-height: var(--spinner-size);
    }
  }

  // ===
  // Types
  // ===

  @include modifier(primary) {
    // Default type
  }

  @include modifier(ghost) {
    --background: #0070f3;

    box-shadow: none;
  }

  @include modifier(accent) {
    --shadow-color: #{button-shadow-color($color-accent)};
    --radiation-color: #{$color-accent-glass};
    --background: linear-gradient(180deg, #ff784a 0%, #ec5a29 100%);
  }

  @include modifier(light line warn warning) {
    --icon-color: #{$color-black};
  }

  @include modifier(light) {
    --shadow-color: #{button-shadow-color($color-mid-grey)};
    --radiation-color: #{$color-primary-glass};
    --text-color: #{$color-dark-grey};
    --icon-color: #{$color-white};
    --icon-background: #{$gradient-primary};
    --background: #{$color-white};
  }

  @include modifier(line) {
    --shadow-color: transparent;
    --radiation-color: #{$color-black-glass};
    --icon-background: #{$color-black-glass};
    --icon-color: #{$color-black};
    --text-color: #5b5b5b;
    --background: #{$color-white};

    border: 2px solid #bababa;
    box-shadow: none;

    &:hover {
      --background: var(--radiation-color);
    }
  }

  @include modifier('line-primary-thin') {
    --radiation-color: #{$color-primary-glass};
    --icon-color: #{$color-white};
    --icon-background: #{$color-primary};
    --text-color: #{$color-primary};
    --background: #{$color-white};

    border: 1px solid $color-primary;
    box-shadow: none;

    &:hover {
      --background: var(--radiation-color);
    }
  }

  @include modifier('line-primary-hover') {
    --shadow-color: transparent;
    --radiation-color: #{$color-black-glass};
    --icon-background: #{$color-black-glass};
    --icon-color: #{$color-black};
    --text-color: #5b5b5b;
    --background: #{$color-white};

    border: 2px solid #bababa;
    box-shadow: none;

    &:hover {
      --radiation-color: #{$color-primary-glass};
      --icon-color: #{$color-white};
      --icon-background: #{$color-mid-primary};
      --text-color: #{$color-mid-primary};
      --background: #{$color-white};

      border: 2px solid $color-mid-primary;
    }
  }

  @include modifier('line-primary') {
    --radiation-color: #{$color-primary-glass};
    --icon-color: #{$color-white};
    --icon-background: #{$color-mid-primary};
    --text-color: #{$color-mid-primary};
    --background: #{$color-white};

    border: 2px solid $color-mid-primary;
    box-shadow: none;

    &:hover {
      --background: var(--radiation-color);
    }
  }

  @include modifier(text) {
    --shadow-color: transparent;
    --radiation-color: transparent;
    --icon-background: #{rgba($color-black, 0.14)};
    --icon-color: #{$color-black};
    --text-color: inherit;
    --background: transparent;
    --height: auto;

    min-width: auto !important;

    + .fp-button--text {
      margin-left: 2px;
    }

    line-height: 1.8;
    border: none;

    @include element(text) {
      padding: 0;
      margin: 0;
      font-size: 0.85em;
      cursor: pointer;
    }

    @include pseudo(hover) {
      opacity: 0.75;

      @include element(text) {
        text-decoration: underline;
      }
    }
  }

  @include modifier(success) {
    --shadow-color: #{button-shadow-color($color-success)};
    --radiation-color: #{$color-success-glass};
    --icon-background: #{rgba($color-black, 0.3)};
    --icon-color: #{$color-white};
    --background: #{$color-success};
  }

  @include modifier(warn warning) {
    --shadow-color: #{button-shadow-color($color-warning)};
    --radiation-color: #{$color-warning-glass};
    --icon-background: #{rgba($color-black, 0.15)};
    --text-color: #{$color-black};
    --icon-color: #{$color-black};
    --background: #{$color-warning};
  }

  @include modifier(error) {
    --shadow-color: #{button-shadow-color($color-error)};
    --radiation-color: #{$color-error-glass};
    --icon-background: #{rgba($color-black, 0.3)};
    --background: #{$color-error};
  }

  // ===
  // Sizes
  // ===

  @include modifier(normal) {
    // Default size
  }

  @include modifier(medium) {
    min-width: 90px;
    height: $size-button-height-medium;
    padding: 0 $size-button-padding-medium;
    font-size: 13px;

    @include element(icon) {
      @include square($size-button-icon-diameter-medium);
    }

    @include element(loader) {
      --spinner-size: 21px;
    }
  }

  @include modifier(light medium) {
    font-family: $heading-font-family;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
  }

  @include modifier(small) {
    min-width: 90px;
    height: $size-button-height-small;
    padding: 0 $size-button-padding-small;
    font-size: 13px;

    @include element(icon) {
      @include square($size-button-icon-diameter-small);
    }

    @include element(loader) {
      --spinner-size: 16px;
    }

    @include element(text) {
      padding: 0 12px;
    }
  }

  @include modifier(small-long) {
    min-width: 135px;
    height: 33px;
    padding: 0 $size-button-padding-small;
    font-size: 11px;
    font-weight: 500;

    @include element(icon) {
      @include square($size-button-icon-diameter-small);
    }

    @include element(loader) {
      --spinner-size: 16px;
    }

    @include element(text) {
      padding: 0 12px;
    }
  }

  @include modifier(tiny) {
    min-width: 90px;
    height: $size-button-height-tiny;
    padding: 0 $size-button-padding-tiny;
    font-size: 11px;

    @include element(icon) {
      @include square($size-button-icon-diameter-tiny);
    }

    @include element(loader) {
      --spinner-size: 12px;
    }

    @include element(text) {
      padding: 0 9px;
    }
  }

  @include modifier(tiny-auto) {
    min-width: auto;
    height: $size-button-height-tiny;
    font-size: 11px;

    @include element(icon) {
      @include square($size-button-icon-diameter-tiny);
    }

    @include element(loader) {
      --spinner-size: 12px;
    }

    @include element(text) {
      height: 10px;
      padding: 0 9px;
      margin: auto 0;

      // 0.5 matters so the height is more even in the button, possibly due to uneven font height
      line-height: 10.5px;
    }
  }

  @include modifier(large) {
    min-width: 200px;
    height: $size-button-height-large;
    padding: 0 $size-button-padding-large;
    font-size: 18px;

    @include element(icon) {
      @include square($size-button-icon-diameter-large);
    }

    @include element(loader) {
      --spinner-size: 30px;
    }

    @include element(text) {
      padding: 0 12px;
    }
  }

  // ===
  // States
  // ===

  @include when(loading) {
    cursor: wait;
  }

  @include when(disabled) {
    &:not(.fp-button--text) {
      --shadow-color: none;
      --radiation-size: 0;
      --icon-background: #{rgba($color-white, 0.4)};
      --icon-color: inherit;
      --text-color: #{$color-light-grey};
      --background: #{$color-disabled};
    }

    cursor: not-allowed;
    border: 0 none;

    @include pseudo(active) {
      transform: ty(0);
    }
  }
}

@include block(icon-button) {
  @include perfect-transition;

  --background-color: #{rgba($color-black, 0)};
  --icon-color: inherit;

  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: 14px;
  color: var(--icon-color);
  cursor: pointer;
  background-color: var(--background-color);
  border-radius: 50%;

  & + & {
    margin-right: 3px;
    margin-left: 3px;
  }

  @include pseudo-class(hover focus) {
    --background-color: #{rgba($color-black, 0.1)};

    outline: none;
  }

  @include element(spinner) {
    $duration: 1.4s;
    $offset: 187;

    position: absolute;
    width: 100%;
    height: 100%;
    animation: icon-button-rotate $duration linear infinite;

    &-path {
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0;
      animation: icon-button-dash $duration ease-in-out infinite;
    }

    @keyframes icon-button-rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes icon-button-dash {
      0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
      }

      100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
      }
    }
  }

  // ===
  // Color
  // ===

  @include modifier(primary) {
    --background-color: #{$color-mid-primary};
    --icon-color: #{$color-white};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-mid-primary, 0.8)};
    }
  }

  @include modifier(error) {
    --background-color: #{$color-error};
    --icon-color: #{$color-white};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-error, 0.8)};
    }
  }

  @include modifier(success) {
    --background-color: #{$color-success};
    --icon-color: #{$color-white};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-success, 0.8)};
    }
  }

  @include modifier(warning) {
    --background-color: #{$color-warning};
    --icon-color: #{$color-white};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-warning, 0.8)};
    }
  }

  @include modifier(accent) {
    --background-color: #{$color-accent};
    --icon-color: #{$color-white};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-accent, 0.8)};
    }
  }

  @include modifier(light) {
    --background-color: #{$color-white};
    --icon-color: #{$color-dark-grey};

    @include pseudo-class(hover focus) {
      --background-color: #{rgba($color-dark-ice, 1)};
    }
  }

  // ===
  // Sizes
  // ===

  @include modifier(small) {
    width: 31px;
    height: 31px;
    font-size: 11px;
  }

  @include modifier(1x) {
    font-size: 12px;
  }

  @include modifier(large) {
    width: 44px;
    height: 44px;
    font-size: 20px;
  }

  @include when(disabled) {
    color: $color-disabled;
    cursor: not-allowed;
  }
}
