@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(hint-message) {
  @extend %full-fill;

  top: calc(100% + 7px);
  display: flex;
  align-items: flex-start;
  height: auto;
  padding-right: 60px;
  padding-left: 20px;
  font-size: 12px;

  @include element(inner) {
    line-height: 1;
  }

  @include when(fill) {
    padding: 0 5px;
  }
}
