@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  * {
    color: #000;
  }

  body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #fff !important;
  }

  .break-after {
    page-break-after: always;
  }

  .page-start {
    padding-top: 50px;
  }

  .application {
    background-color: #fff !important;
  }

  .print-only {
    display: block;
    width: 21cm;
    min-height: 29.7cm;
    font-size: 14px;
    line-height: 18px;

    ~ * {
      display: none !important;
    }
  }

  @page {
    size: a4 portrait;
    padding: 0 !important;
    margin: 0 !important;
  }
}
