@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

%shape-box-common {
  position: relative;
  width: 40px;
  height: 40px;
  background-color: $color-info;
}

.circleBox {
  @extend %shape-box-common;

  border-radius: 50%;
}

.squareBox {
  @extend %shape-box-common;
}

.shapedContent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
