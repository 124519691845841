@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.root {
  height: 100%;
  overflow: hidden;

  .row {
    height: 100%;
  }

  .label {
    font-family: $heading-font-family;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    color: $color-mid-grey;
    letter-spacing: 0.04em;
  }
}

.contentRoot {
  background: $color-light-ice;
}

.itemsRoot {
  background: $color-white;

  @include mq($from: md) {
    height: 100%;
  }
}

.contentHeaderBox {
  h2 {
    font-size: 28px;
    font-weight: bold;
    line-height: 42px;
  }
}

.status {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 15px;
}

.headerBox {
  h5 {
    @include ellipsis();
  }
}

.itemsBox {
  @extend %typo-sm;

  font-family: $heading-font-family;

  .item {
    // @include perfect-transition();
    // transition: transform 150ms;

    color: $color-black-real;

    &:hover {
      font-weight: bold;
      color: $color-primary;
      // transform: translateX(5px);

      cursor: pointer;
    }
  }
}

.itemActive {
  font-weight: bold;
  color: $color-primary !important;
}

.itemDisabled {
  color: $color-disabled !important;
  pointer-events: none;
}
