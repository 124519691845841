@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































@import '@scss';

.iconCc {
  width: 100% !important;
  max-width: 1.125em;
  color: $color-black;
}
.labelBox {
  @extend %font-input-label;

  flex-shrink: 0;
}
