@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.switchHint {
  padding-left: 11px;
  margin-bottom: -8px;
  font-size: 12px;
  color: $color-form-hints;
}

.card {
  @include mq($from: md) {
    padding: 40px 15px;
  }

  @include mq($until: md) {
    padding: 25px 10px;
  }

  @include mq($until: sm) {
    padding: $card-px-sm;
  }
}

.header {
  @include content-padding(70, 0);
}

.tab {
  margin-top: 32px;

  @include mq($until: md) {
    margin-top: 24px;
  }
}

.form {
  @include content-padding(0, 36);
}

.root {
  height: 100%;
}

.addMember {
  @include mq($until: sm) {
    display: none;
  }
}

button.addBtn {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
}
button.filtBtn {
  position: absolute;
  top: -5px;
  left: 160px;
  transform: translateY(-100%);
}

.container {
  position: relative;
}

.fieldLabel {
  font-family: $heading-font-family;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.fieldDisplayValue {
  padding-bottom: 25px;
  font-family: $heading-font-family;
  font-size: 16px;
  line-height: 24px;
  // @include ellipsis();
  word-break: break-word;
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  margin: 50px 0;
  background-color: $color-dark-ice;
}

.subtitle {
  margin: 80px 0 40px;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: #666;
}

.description {
  color: $color-dark-grey;
}

.permissions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &Item {
    width: 50%;
    padding-right: 20px;
  }
}
