@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














@import '@scss';

.container {
  min-width: 250px;
  padding: 0 12px 0 12px;

  @include mq($until: mobile) {
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 16px;
  }
}
