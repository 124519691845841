@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(input-arc) {
  --current-state-color: #{$color-primary};

  @extend %input-appearance-normal;

  height: $size-input-height-normal;
  font-size: $size-input-font-normal;

  // ===
  // Pseudo Class
  // ===

  @include pseudo(hover) {
    &:not(.is-disabled):not(.is-readonly):not(.is-hasState) {
      @extend %highlight-current-hover-component;
    }
  }

  // use is-focus for immutating the :focus state of an input
  @include when(focus) {
    @extend %highlight-current-focus-component;

    @include element(icon) {
      color: var(--current-state-color);
    }
  }

  // ===
  // Elements
  // ===

  @include element(inner-box) {
    margin-top: 10.6px;
  }

  @include element(inner) {
    @extend %full-fill;

    position: relative;
    order: 1;
    padding: 0 $size-input-padding-normal;
    color: inherit;
    cursor: inherit;
    background-color: transparent;
    border: none;

    @include placeholder {
      color: transparent;
    }

    @include pseudo-class(focus) {
      outline: none;
    }
  }

  @include element(placeholder) {
    @extend %full-fill;

    display: flex;
    align-items: center;
    padding-left: $size-input-padding-normal;
    margin: 0;
    color: $color-mid-grey;
    pointer-events: none;
  }

  @include element(icon) {
    order: 3;
    margin: 0 13px;

    @include emphasize {
      font-size: 14px;
      color: inherit;
    }

    @include when(pointer) {
      cursor: pointer;
    }
  }

  @include element(label) {
    @extend %typography-small;

    padding-left: 11px;
    font-size: 14px;
    font-weight: 600;
    color: $color-dark;
  }

  @include element(counter) {
    position: absolute;
    top: 100%;
    right: 15px;
    padding-top: 5px;
    font-size: 14px;
  }

  // ===
  // Modifiers
  // ===

  @include modifier(small) {
    height: $size-input-height-small;
    font-size: $size-input-font-small;
  }

  // ===
  // States
  // ===

  @include when(disabled) {
    color: $color-text-disabled;
    cursor: not-allowed;
    background-color: $color-mid-ice;

    @include element(placeholder) {
      color: inherit;
    }
  }

  @include when(readonly) {
    color: $color-mid-grey;
    cursor: default;
  }

  @include when(error) {
    @extend %errorify-current-component;
  }

  @include when(success) {
    @extend %successify-current-component;
  }
}
