@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






























@import '@scss';

.currencyWrap {
  display: inline-block;
  font-weight: inherit;
}

.currencySign {
  margin-right: 1px;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: inherit;
  vertical-align: text-top;
}
