@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































































































































@import '@scss';
.root {
  width: 100%;
}
