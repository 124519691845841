// https://github.com/sass-mq/sass-mq

// usage:
// .foo {
//   @include mq($from: mobile, $until: tablet) {
//     background: red;
//   }
//   @include mq($from: tablet) {
//     background: green;
//   }
// }

// !!IMPORTANT!!
// always sync
// with vuetify's breakpoints https://vuetifyjs.com/en/framework/breakpoints
// otherwise we may get issues with sass and js breakpoints getting out of sync
// which can cause confusion of styling at various breakpoints
$mq-breakpoints: (
  mobile-lg: 425px,
  sm: 600px,
  mobile: 600px,
  md: 960px,
  tablet: 960px,
  lg: 1264px,
  desktop: 1264px,
  xl: 1904px,
  wide: 1904px,
);

$mq-breakpoints-height: (
  skinny: 820px,
);

@import '~sass-mq';

@mixin mq-height($until: 'sm') {
  @media screen and (max-height: map-get($mq-breakpoints-height, $until)) {
    @content;
  }
}
