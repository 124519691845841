@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































@import '@scss';

.headerWhenLeft {
  padding-left: $size-page-for-form-heading-padding;
}

.headingBox {
  margin-top: 20px;
  margin-bottom: 30px;
}

:global(.fp-stepper) {
  + .headingBox {
    margin-top: 50px;

    @include mq($until: sm) {
      margin-top: 30px;
    }
  }
}

h3.heading {
  font-size: 28px;
  line-height: 42px;
  color: $color-white;
}

small.tip {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: $color-white;
}
