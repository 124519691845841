@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
.pac-item {
  padding: 10px 15px;
}

.pac-logo {
  &::after {
    display: none;
  }
}
