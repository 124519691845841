@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(select) {
  @extend %vuetify-input-restyle;

  @include when(hasLabel) {
    > .v-select {
      .v-select__slot > input {
        margin-top: 5px !important;
      }
    }
  }

  @include when(withChip) {
    > .v-select {
      .v-chip__content {
        color: #{$color-white};
      }

      .v-select__selections {
        .fp-select__item-chip + input {
          display: none !important;
        }
      }
    }
  }

  > .v-select {
    .v-select__slot > input {
      align-self: unset;
    }

    .v-select__selections > input {
      display: inline-block;
      width: 70px;
    }

    .v-select__selections .v-avatar {
      height: inherit !important;
    }

    &:not(.v-text-field--single-line) {
      &.v-select--chips .v-select__selections.v-select__selections {
        min-height: $size-input-height-normal;
        padding: 5px 0 4px;
      }
    }
  }

  .v-input__icon--clear > .v-icon {
    font-size: 18px !important;
  }

  .v-chip:not(.v-chip--active) {
    height: 28px;
    padding: 0 10px;
    color: $color-white;

    &:not(.v-chip--active) {
      background-image: $gradient-primary;
    }

    .v-icon {
      color: $color-white;
    }

    &.v-chip--disabled {
      color: $color-light-ice;
    }

    &.v-chip--active {
      background-image: $gradient-primary;
    }
  }

  @include element(item-avatar) {
    margin-right: 10px;
  }

  @include element(no-data) {
    padding: 3px 15px;
  }

  @include when(ice) {
    @extend %vuetify-input-restyle-ice;

    .v-chip:not(.v-chip--active) {
      color: $color-mid-primary;
      background-color: $color-white;
      background-image: none;

      .v-icon {
        color: $color-light-primary;
      }
    }
  }
}

.v-autocomplete__content.v-menu__content.v-menu__content {
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12);
}
