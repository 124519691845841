@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

%chip-layout {
  display: flex;
  align-items: center;
  height: $size-input-height-normal;
  padding: 5px 10px;
  border-radius: 6px;
}

.v-treeview-node__root {
  > .v-treeview-node__toggle {
    width: 24px;
  }
}

.v-treeview-node__checkbox {
  margin: 0 6px;
}

@include block(locations) {
  // margin-bottom: 20px;

  @include element(label) {
    @extend %font-label;
  }

  @include element(label-wrapper) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 30px;
    padding-right: 5px;
  }

  @include element(activator) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
    list-style: none;

    @include emphasize {
      padding-left: 0;
    }

    @include pseudo-class(focus) {
      outline: 0;
    }
  }

  @include element(presets) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 0;
    list-style: none;

    @include emphasize {
      padding-left: 0;
    }
  }

  @include element(preset-pill) {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 25px;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 8px;
    margin-bottom: 7px;
    font-size: 13px;
    font-weight: bold;
    color: $color-black;
    cursor: pointer;
    background-color: $color-white;
    border-radius: 20px;

    @include element(flag) {
      max-width: 30px;
      max-height: 15px;
      border-radius: 3px;
    }

    > .name {
      display: block;
      flex-grow: 1;
      padding-left: 5px;
      white-space: nowrap;
    }

    > .icon {
      width: 45px;
    }

    &:hover {
      color: $color-primary;
    }
  }

  @include element(add-button) {
    --color: #{rgba(black, 0.24)};

    @extend %chip-layout;

    color: #888;
    cursor: pointer;
    border: 2px solid $color-dark-ice;

    > .fp-icon {
      margin-right: 10px;
      font-size: 20px;
    }

    &:only-child {
      flex-basis: 100%;
      border-style: solid;
    }

    @include pseudo-class(focus hover) {
      background-color: $color-light-ice;
      outline: 0;
    }
  }

  @include element(value add-button) {
    flex-basis: 49.3%;
    margin-bottom: 10px;
  }

  @include element(message) {
    padding: 0 12px;
    margin-top: -3px;
    font-size: 12px;
  }

  @include element(hint) {
    padding: 0 12px;
    margin-top: -5px;
    margin-bottom: 23px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  @include element(dialog) {
    @include card-border();

    width: 100%;
    padding: 0;
    border-radius: 4px;
  }

  @include element(header) {
    min-height: 160px;
    padding: 20px;
    color: $color-white;
    background: $gradient-primary;
    border-radius: 8px 8px 0 0;

    > h3 {
      color: inherit;
    }

    > small {
      padding-left: 5px;
    }
  }

  @include element(body) {
    display: flex;
    border-bottom: 1px solid $color-disabled;

    @include element(col) {
      position: relative;
      flex: 0 0 50%;
      max-width: 50%;

      @include pseudo-class(first-of-type) {
        border-right: 1px solid $color-disabled;
      }
    }
  }

  @include element(actions) {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 6px 0;
  }

  @include element(col-header) {
    display: flex;
    align-items: center;
    height: 62px;
    padding: 10px;

    > h5 {
      display: inline-block;
      margin-right: auto;
      font-size: 14px;
      font-weight: bold;
      color: $color-black;
    }
  }

  @include element(filter) {
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: 10px 15px;
    padding-right: 5px;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 4px;

    > .fp-icon {
      margin-right: 10px;
      font-size: 12px;
      color: $color-mid-grey;
    }

    @include element(filter-input) {
      display: block;
      flex-grow: 1;
      height: 40px;
      margin: -10px 0;
      font-size: 13px;

      &:focus {
        outline: none;
      }
    }

    @include when(active) {
      > .fp-icon {
        color: $color-primary;
      }
    }
  }

  @include element(scroll-container) {
    --scroll-container-height: 60vh;

    position: relative;
    height: var(--scroll-container-height);

    @include pseudo-element(after) {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 40px;
      pointer-events: none;
      content: '';
    }

    @include pseudo-element(after) {
      bottom: 0;
      // ⚠️ do not use transparent with linear gradient, safari will
      // translate transparent to rgba(0 0 0 0 0) and cause a weird
      // black box
      background-image: linear-gradient(
        to top,
        $color-white,
        rgba(255, 255, 255, 0)
      );
    }
  }

  @include element(scroll-inner) {
    height: 100%;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;

    .v-treeview {
      &:empty {
        padding-top: calc(25vh - 1.2em);
        color: $color-mid-grey;
        text-align: center;

        &::before {
          font-size: 13px;
          content: 'No result for current search';
        }
      }
    }
  }

  @include element(global-item) {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 40px;
    margin-left: -10px;
    background-color: $color-white;
    border-top: 1px solid $color-disabled;
    border-bottom: 1px solid $color-disabled;
  }

  @include element(global-text) {
    margin-left: 10px;
    user-select: none;
  }

  @include element(empty searching) {
    display: block;
    padding-top: calc(30vh - 1.2em);
    color: $color-mid-grey;
    text-align: center;
  }

  @include element(button) {
    display: inline-flex;
    padding: 4px 16px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;
    background-color: #d8d8d8;
    border-radius: 20px;

    &:hover {
      opacity: 0.9;
    }

    @include modifier(primary) {
      font-weight: bold;
      color: $color-white;
      background-color: $color-primary;

      &:hover {
        box-shadow: 0 0 0 5px $color-primary-glass;
      }
    }
  }

  @include element(footer) {
    display: flex;
    justify-content: flex-end;
    padding: 12px 16px;
  }

  @include when(error) {
    @include element(message) {
      color: $color-error;
    }

    @include element(label) {
      color: $color-error;
    }

    @include element(add-button) {
      --color: #{$color-error};

      color: var(--color);
      border-color: currentColor;
    }
  }
}

@include block(locations-block) {
  @include perfect-transition;

  @extend %chip-layout;

  position: relative;
  background-color: $color-white;
  border: 1px solid $color-disabled;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $color-primary;

    &:not(.is-plain, .is-readonly) {
      @extend %highlight-current-hover-component;
    }
  }

  @include when(plain) {
    background-color: transparent;
    border: none;
    border-radius: 0;

    &:hover {
      background-color: $color-light-ice;
    }

    > .fp-icon {
      margin-right: 10px;
    }
  }

  @include element(flag-wrapper) {
    @include circle(25px);

    position: relative;
    overflow: hidden;
  }

  @include element(flag) {
    position: absolute;
    width: 100%;
  }

  @include element(content) {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 4%;
    font-size: 13px;
    line-height: 1;
  }

  @include element(country) {
    margin-bottom: 3px;
  }

  @include element(divisions) {
    font-size: 12px;
    color: $color-mid-grey;
    word-break: break-word;
  }

  > .fp-icon.edit {
    font-size: 12px;
  }

  @include element(delete) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 100%;

    > .fp-icon {
      font-size: 12px;
    }
  }
}
