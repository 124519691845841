@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.adNickname {
  display: block;
  width: 460px;
  padding-right: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include mq($until: sm) {
    width: 150px;
    padding-right: 0;
  }

  @include mq($from: sm, $until: md) {
    width: 350px;
    padding-right: 50px;
  }
}

.textNormal {
  color: $color-error;
}

.textSuccess {
  color: $color-success;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-right: -50px;

  .actions {
    display: flex;
    margin-left: auto;
    color: $color-mid-grey;
  }

  .title {
    max-width: 99%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.hint {
  @extend %font-label-hint;

  margin-top: 4px;
}

.denseInput {
  height: 68px;
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  margin: 50px -50px;
  background-color: $color-dark-ice;

  &:not(:empty) {
    margin: 90px -50px;
  }
}

.card {
  @include card-form-padding();
}

.section {
  margin-bottom: 35px;
}

.value {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 10px;
  font-size: 16px;
  line-height: 32px;
  color: $color-dark-grey;
  word-break: break-word;

  :global(.fp-chip) {
    margin-top: 7px;
    margin-right: 10px;
  }

  .ratingImg {
    max-width: 25px;
    margin-right: 10px;
  }
}
