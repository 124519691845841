@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.activator {
  position: relative;
  :global {
    .fp-icon {
      font-size: 26px;
    }

    .fp-icon-button {
      width: 50px;
      height: 50px;
    }
  }
}

.ripple {
  color: $color-mid-primary-glass !important;
  caret-color: $color-mid-primary-glass !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}
