@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(checkbox) {
  @include perfect-transition;

  position: relative;
  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: $size-radio-min-height-normal;
  padding: 10px 17px;
  color: $color-mid-grey;
  cursor: pointer;
  background-color: $color-white;
  border: 2px solid $color-dark-ice;
  border-radius: 6px;
  box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12), 0 0 0 1px transparent,
    0 0 0 5px transparent;

  &:not(:first-of-type):not(.is-noLabel) {
    margin-left: 15px;
  }

  @include pseudo-class(hover focus) {
    &:not(.is-disabled):not(.is-light):not(.is-checked):not(.is-error) {
      cursor: pointer;
      border-color: $color-mid-primary;
      box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12),
        0 0 0 1px $color-mid-primary, 0 0 0 5px $color-primary-glass;

      @include element(indicator) {
        @include pseudo(before) {
          border-color: $color-primary-glass;
        }
      }
    }

    outline: none;
  }

  // ===
  // Elements
  // ===

  @include element(label) {
    line-height: 1.3;
    text-align: center;
  }

  @include element(disabled-text) {
    position: absolute;
    bottom: -#{$size-input-indicator / 2};
    background: $color-disabled;
    border-radius: 4px;

    @include mq($until: md) {
      .v-chip__content {
        font-size: 12px;
      }
    }

    @include mq($until: mobile) {
      .v-chip__content {
        font-size: 10px;
      }
    }
  }

  @include element(indicator 'ban-icon') {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: txy(-50%, 50%);
  }

  @include element(indicator) {
    @include perfect-transition;
    @include circle($size-input-indicator, $color-white);

    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $color-white;

    @include pseudo(before) {
      @include perfect-transition;

      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      border: 2px solid $color-dark-ice;
      border-radius: 50%;
    }

    > .fp-icon {
      display: none;
      transform: scale(0.8);
    }
  }

  // ===
  // States
  // ===

  @include when(light) {
    @include element(indicator) {
      background-color: transparent;
      border-color: $color-dark-ice;
    }

    @include pseudo-class(hover) {
      @include element(indicator) {
        @include pseudo(before) {
          border-color: $color-mid-primary;
        }
      }
    }
  }

  @include when(settled) {
    color: $color-mid-primary;
    border-color: $color-mid-primary;
    box-shadow: 0 4px 24px transparent, 0 0 0 1px $color-mid-primary,
      0 0 0 5px $color-primary-glass;

    @include element(indicator) {
      display: flex;
      background-color: transparent;
      background-image: $gradient-primary;

      @include pseudo(before) {
        border-color: transparent;
        transition: border-color 0ms;
      }

      > .fp-icon {
        display: block;
      }
    }

    @include when(noLabel) {
      @include element(indicator) {
        box-shadow: none;
      }
    }

    @include when(light) {
      @include element(indicator) {
        box-shadow: none;
      }
    }

    @include when(indeterminate) {
      @include element(indicator) {
        background-image: $gradient-primary;
      }
    }
  }

  @include when(disabled) {
    cursor: not-allowed;
    background-color: transparent;
    border-color: $color-disabled;
    box-shadow: none;
  }

  @include when(noLabel) {
    flex: 0;
    min-height: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    box-shadow: none;

    @include element(indicator) {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }

    @include pseudo-class(hover) {
      &:not(.is-disabled):not(.is-light) {
        box-shadow: none;

        @include element(indicator) {
          @include pseudo(before) {
            @include perfect-transition;

            border-color: $color-mid-primary;
          }
        }
      }
    }
  }
}

@include block('checkbox-group') {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  @include element(label) {
    @extend %font-label;
  }

  @include element(message) {
    @extend %typography-small;

    padding: 0 12px;
    margin-top: 10px;
    font-size: 12px;
  }

  @include when(error) {
    @include element(message) {
      color: $color-error;
    }

    @include element(label) {
      color: $color-error;
    }

    @include block(checkbox) {
      &:not(.is-disabled) {
        border: 2px solid $color-error;

        @include element(indicator) {
          border: 2px solid $color-error;
        }

        @include element(label) {
          color: $color-error;
        }
      }
    }
  }
}
