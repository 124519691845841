@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(skeleton) {
  @include perfect-transition;

  position: relative;
  display: block;
  flex: auto 0 0;
  width: var(--width);
  height: var(--height);
  margin-top: var(--top-margin);
  margin-right: var(--right-margin);
  margin-bottom: var(--bottom-margin);
  margin-left: var(--left-margin);
  overflow: hidden;
  background-color: rgba($color-disabled, 0.8);
  border-radius: 4px;

  &:not(.is-noAnimate) {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba($color-white, 0.5);
      border-radius: inherit;
      contain: strict;
      will-change: transform;
      animation: skeleton-shine 0.9s ease-in-out infinite;
      animation-delay: var(--delay);
    }
  }

  @include when(circle) {
    --diameter: var(--width);

    width: var(--diameter);
    height: var(--diameter);
    border-radius: 50%;
  }

  @include when(round) {
    border-radius: 100px;
  }

  @include when(useTextColor) {
    background-color: currentColor;
  }
}

@keyframes skeleton-shine {
  0% {
    opacity: 0.5;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(100%, 0, 0);
  }
}
