@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.closeButton {
  position: absolute;
  top: 8px;
  right: 8px;
}

.dialogInner {
  display: flex;
  flex-direction: column;
  align-items: center;

  > h3 {
    padding: 0 5%;
    margin-bottom: 30px;
    text-align: center;
  }
}

.dialogContent {
  margin-bottom: 30px;
  color: $color-dark-grey;
  text-align: center;
}

.dialogTextarea {
  width: 100%;
}

.dialogConfirmInput {
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid $color-dark-ice;
}
