@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
// Used in Application
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// styles variables and utilities from src/styles.
@import 'index';
@import 'animation';
@import 'vuetify';

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: $color-white;
  background-color: $color-mid-primary;
}

body {
  color: $color-black;
}

body,
html {
  @extend %typography-small;

  // Stop horizontal mobile scroll
  overflow-x: hidden;
  // vuetify overrides html styling
  // temporary fix until vuetify 2.0 comes out q2 2019
  // https://vuetifyjs.com/en/getting-started/roadmap
  // vuetify 2 seems to convert stylus to scss, then we
  // maybe can override the base scss variables. We can
  // overidde the stylus vars now but probably no point
  // given how close vuetify 2 is.
  font-family: $content-font-family;
  font-size: 16px !important;
  scroll-behavior: smooth;

  &[data-scroll='no-scroll'] {
    overflow: hidden;
  }
}

.application.application.application {
  background-color: $color-light-ice;
}

#app {
  transition: 0.2s $perfect-timing;

  &.blur {
    filter: blur(8px);
    transform: scale(0.94);
  }
}

// ===
// Base element styles
// ===

a,
a:visited {
  color: $color-mid-primary;
  text-decoration: underline;
}

h1,
h2,
h3,
h4 {
  @extend %font-heading;
}

h1 {
  @extend %typography-xxlarge;

  font-weight: bold;
}

h2 {
  @extend %typography-xlarge;
}

h3 {
  @extend %typography-large;

  font-weight: bold;
}

h4 {
  @extend %typography-medium;

  font-weight: bold;
}

h5 {
  @extend %typography-small;

  font-family: $heading-font-family;
  font-weight: bold;
}

h6 {
  @extend %typography-small;

  font-family: $heading-font-family;
}

ul {
  margin-bottom: 1rem;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='time']::-webkit-clear-button {
  color: $color-white;
}

// Globalised Classes

.w-100 {
  width: 100%;
}

.fp-clickable {
  &:hover {
    cursor: pointer;
  }
}

// ===
// Vendor
// ===
