@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

















































































@import '@scss';

$title-max-width: 320;

.header {
  display: flex;
  justify-content: space-between;
  margin-right: -10px;

  .title {
    max-width: #{$title-max-width}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include mq($until: md) {
      max-width: #{$title-max-width - 40}px;
    }

    @include mq($until: sm) {
      max-width: #{$title-max-width - 140}px;
      font-size: 22px;
    }

    @include mq($until: mobile-lg) {
      max-width: #{$title-max-width - 200}px;
      font-size: 20px;
    }
  }
  .is-admin {
    @include mq($until: md) {
      max-width: #{$title-max-width - 140}px;
    }
  }

  .actions {
    display: flex;
    margin-left: auto;
    color: $color-mid-grey;

    @include mq($until: mobile) {
      display: none;
    }
  }
}

.subtitle {
  margin-top: -10px;
  margin-bottom: 16px;
  font-size: 14px;
  color: #8e8e8c;
}
