@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































@import '@scss';

.container.container {
  z-index: 6;
  border-top: 1px solid $color-disabled;
  box-shadow: none;
}

.slotBox {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 24px;
}
