@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@function toggle-shadow-color($color) {
  @return rgba(darken($color, 31%), 0.8);
}

@include block(switch) {
  --tracker-background: #{$color-disabled};
  --slider-background: linear-gradient(
    180deg,
    #{$color-dark-grey} 0%,
    #{$color-dark-grey} 100%
  );
  --icon-color: #{$color-white};
  --text-color: #{$color-black};

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  contain: layout;

  @include pseudo-class(focus hover) {
    outline: none;
  }

  @include element(tracker) {
    @include perfect-transition;

    position: relative;
    width: 85px;
    height: 40px;
    margin: 3px 0;
    background: var(--tracker-background);
    border-radius: 100px;
  }

  @include element(slider) {
    @include perfect-transition;
    @include circle(44px);

    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: var(--icon-color);
    background: var(--slider-background);
    box-shadow: 0 4px 24px rgba(122, 122, 122, 0.22);
    transform: ty(-50%);
    will-change: transform, left;
    contain: content;
  }

  @include element(text) {
    margin-left: 25px;
    color: var(--text-color);
    user-select: none;

    @extend %font-toggle;
  }

  @include element(label) {
    @extend %font-label;
  }

  @include element(hint) {
    @extend %font-label-hint;
  }

  @include element(title) {
    margin-bottom: -6px;
    font-family: $system-default-font-family;
    font-size: 12px;
    color: $color-form-hints;
    text-overflow: visible;
  }

  // ===
  // Types
  // ===

  @include when(active) {
    --tracker-background: #{$color-dark-ice};
    --slider-background: #{$gradient-primary};
    --text-color: #{$color-mid-primary};

    @include element(slider) {
      left: 100%;
      margin-left: -32px;
      transform: ty(-50%) rotate(360deg);
    }
  }

  @include when(disabled) {
    --text-color: #{$color-disabled};
    --tracker-background: #{rgba($color-disabled, 0.5)};
    --slider-background: #{$color-disabled};

    cursor: not-allowed;
  }

  // ===
  // Sizes
  // ===

  @include modifier(sm) {
    @include element(tracker) {
      width: 50px;
      height: 25px;
    }

    @include element(slider) {
      width: 25px;
      height: 25px;
      svg {
        width: 10px;
        height: 12px;
      }
    }

    @include element(text) {
      margin-left: 10px;
      font-size: 12px;
    }

    @include when(active) {
      @include element(slider) {
        margin-left: -26px;
      }
    }
  }

  @include modifier(lg) {
    @include element(tracker) {
      width: 100px;
      height: 50px;
    }

    @include element(slider) {
      width: 50px;
      height: 50px;
    }

    @include when(active) {
      @include element(slider) {
        margin-left: -50px;
      }
    }
  }
}
