@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.head {
  text-align: center;
  // border-bottom: 2px solid rgba($color-light-grey, 0.2);
  h4 {
    word-break: break-word;
  }
}

.nameBox {
  width: 100%;
}

.divider {
  position: relative;
  width: 25%;
  height: 3px;
  margin: 22px auto;
  background: $color-accent;

  // background: rgba($color-accent, 0.8);
}

.subtitle {
  font-weight: 500;
  color: $color-dark-grey;
}

// .actions {
//   border-top: 2px solid rgba($color-light-grey, 0.2);
// }

.actionLabel {
  font-family: $heading-font-family;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.actionValue {
  font-family: $heading-font-family;
  font-size: 16px;
  line-height: 24px;
}

// crud styles

.fieldBox {
  min-height: 90px;
}

.fieldLabel {
  font-family: $heading-font-family;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.fieldDisplayValue {
  // @include ellipsis();

  font-family: $heading-font-family;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
}
