@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(bulk-upload) {
  position: relative;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  padding: 40px 0 120px;
  overflow-y: auto;
  color: $color-dark-grey;
  background-color: $color-white;

  @include mq($until: tablet) {
    padding: 5vw 0;
  }

  @include element(file-browser) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 230px;
    margin-bottom: 50px;
    cursor: pointer;
    border: 2px dashed $color-dark-ice;
    border-radius: 8px;
    perspective: 100px;

    @include perfect-transition();

    &.is-hover,
    &:hover {
      background-color: rgba($color-mid-primary, 0.04);
      border-color: $color-light-primary;

      @include element(browser-icons) {
        > .fp-icon {
          color: $color-mid-primary;
        }

        > .object {
          transform: translateZ(10px);
        }

        > .shadow {
          opacity: 0.2;
        }

        > .shadow.left {
          transform: tx(-12px) rotate(-19deg);
        }

        > .shadow.right {
          transform: tx(12px) rotate(19deg);
        }
      }
    }
  }

  @include element(browser-icons) {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 60px;
    margin: 10px 0;
    font-size: 42px;

    @include perfect-transition();

    > .fp-icon {
      position: absolute;
      top: 0;
      color: inherit;

      @include perfect-transition();
    }

    > .object {
      z-index: 2;
    }

    > .shadow {
      opacity: 0;
      transform-origin: 50% 100%;
    }
  }

  @include element(file-block) {
    position: relative;
    display: flex;
    align-items: center;
    width: 220px;
    padding: 10px;
    overflow: hidden;
    border: 1px solid $color-dark-ice;
    border-radius: 4px;

    @include when(previewable) {
      cursor: zoom-in;
    }

    @include when(error) {
      color: $color-error;
    }

    @include mq($until: tablet) {
      width: 140px;
    }
  }

  @include element(progress) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-mid-ice;
    transform-origin: 0 50%;
  }

  @include element(file-name file-ext) {
    position: relative;
    z-index: 2;
  }

  @include element(file-name) {
    max-width: 100%;
  }

  @include element(browser-label) {
    text-align: center;

    @include perfect-transition();

    a {
      text-decoration: none;
    }

    > small {
      display: block;
    }
  }

  @include element(list-title) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > h4 {
      padding: 0 16px;
      color: inherit;
    }
  }

  @include element(result) {
    font-size: 14px;
  }

  @include element(status-cell) {
    position: relative;
    max-width: 350px;

    @include mq($until: tablet) {
      max-width: 250px;
    }
  }

  @include element(footer) {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    background-color: $color-white;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
}
