@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































@import '@scss';
@import '~watson-scss';

.root {
  position: relative;
}

.stripeCard {
  width: 100%;
  height: 56px;
  padding: 17px 20px;
  border: 2px solid $color-dark-ice;
  border-width: 2px;
  border-radius: 8px;

  @include perfect-transition;

  &:global(.StripeElement--focus) {
    background-color: rgba($color-primary, 0.03);
    border: 2px solid $color-mid-primary;
    box-shadow: 0 0 0 3px rgba($color-primary, 0.04);
  }
}

.errorMsg {
  color: $color-error;
}
