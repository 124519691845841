@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(card) {
  --padding: 15px 20px;
  --header-padding-bottom: 10px;

  position: relative;
  padding: var(--padding);
  background: $color-white;
  border-radius: $size-card-border-radius;

  @include mq($until: sm) {
    padding: $card-px-sm;
  }

  @include element(label) {
    @include when(large) {
      @include mq($until: sm) {
        padding-bottom: var(--header-padding-bottom);
      }
    }
  }

  @include element(dialog) {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: $layer-modal-z-index;
    background-color: $color-white;
    transform: translate(-50%, -50%);
  }

  @include element(header) {
    padding: 0 10px var(--header-padding-bottom) 10px;
    font-family: $heading-font-family;
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: $color-dark-grey;
    letter-spacing: $content-letter-spacing;
    border-bottom: 1px solid $color-dark-ice;

    @include when(large) {
      padding-left: 0;
      font-size: 20px;
      line-height: 30px;
      border-bottom: none;
    }

    @include when(noBodyPad) {
      padding: 0;
      padding: 12px 10px 12px 10px;
      margin-right: 17px;
      margin-left: 17px;
    }
  }

  @include element(footer) {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: ty(40%);
  }

  @include when(noBodyPad) {
    padding: 0 !important;
  }

  @include when(dark) {
    background: $color-dark;
  }

  @include modifier(big-shadow) {
    box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12);
  }

  @include modifier(small-shadow) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }

  @include modifier(no-shadow) {
    box-shadow: none;
  }
}
