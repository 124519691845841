@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































































































































































@import '@scss';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $color-light-ice;
}

.pageHeadCurtain {
  --exceeding-height: 0%;
  --min-height: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: var(--min-height);
  padding-top: #{$size-gap-between-header-content};

  &::before {
    @extend %full-fill;

    top: #{-1 * $size-header-height};
    height: calc(100% + var(--exceeding-height) + #{$size-header-height});
    content: '';
    background: linear-gradient(81.16deg, #0070f3 0%, #57a3fc 99.6%);
  }

  > .pageHeadPortal {
    @extend %center-content;

    z-index: 1;
    padding-bottom: 10px;
  }

  @include mq($until: mobile) {
    padding-top: 0;
  }
}

.sideMenuPortal {
  position: relative;
  z-index: 10;
}

.content {
  @extend %center-content;

  position: relative;
  margin: 0 auto;
}

.verificationTip {
  padding-right: 180px;

  &:global(+ .fp-button) {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-self: center;
  width: 100%;
  max-width: 1600px;
}
