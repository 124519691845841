@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

.v-menu__content {
  @include card-border();

  padding: 6px 0;
  border-radius: 6px;
  box-shadow: 0 8px 34px -2px rgba(18, 18, 18, 0.18);

  .v-select-list.v-card {
    box-shadow: none !important;
  }
}
