@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(stepper) {
  @extend %font-stepper;

  --color-disabled: #{rgba(149, 152, 171, 0.19)};
  --color-black: #000;
  --label-color: #{$color-white};
  --line-width: 72px;

  display: flex;
  margin: 0 calc(-1 * var(--line-width));
  list-style: none;

  @include emphasize {
    padding-left: 0;
  }

  @include mq($until: 1400px) {
    --line-width: 60px;
  }

  @include mq($until: lg) {
    --line-width: 40px;
  }

  // 👋 Step component
  @include element(step) {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    @include when(clickable) {
      cursor: pointer;
    }

    @include pseudo(first-of-type) {
      @include element(line) {
        @include modifier(left) {
          height: 0;
          background-color: transparent;
        }
      }
    }

    @include pseudo(last-of-type) {
      @include element(line) {
        @include modifier(right) {
          height: 0;
          background-color: transparent;
        }
      }
    }

    &:not(.is-active) {
      @include element(line) {
        @include modifier(left) {
          @include pseudo-class(before) {
            transition-delay: 0.15s;
          }
        }
      }
    }

    @include when(active) {
      @include element(circle) {
        background-color: transparent;
      }

      @include element(background) {
        background-color: $color-dark-primary;
        opacity: 1;
        will-change: transform;
        animation: bounce 1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
      }

      @include element(indicator) {
        background-color: #fff;
        opacity: 1;
        will-change: transform;
        animation: bounce 1.2s forwards;
      }

      @include element(line) {
        @include pseudo-class(before) {
          transform: tx(-0%);
        }

        @include modifier(right) {
          @include pseudo-class(before) {
            transition-delay: 0.15s;
          }
        }
      }
    }

    // Previously most advanced step number
    @include when(standByActive) {
      @include element(circle) {
        cursor: pointer;
      }

      @include element(indicator) {
        background-color: $color-lighter-primary;

        @include pseudo-class(hover) {
          background-color: $color-white;
        }
      }

      @include element(line) {
        @include modifier(right) {
          @include pseudo-class(before) {
            transform: tx(-100%);
          }
        }
      }
    }

    @include when(completed) {
      &:not(.is-active) {
        @include element(circle) {
          cursor: pointer;
          background-color: transparent;
        }

        @include element(line) {
          @include pseudo-class(before) {
            transform: tx(-0%);
          }
        }

        @include element(indicator) {
          width: 100%;
          height: 100%;
          background-color: $color-dark-primary;
          opacity: 1;
          transform: scale(1);
        }

        @include element(circle) {
          @include pseudo-class(hover) {
            @include element(indicator) {
              background-color: $color-darker-primary;
              box-shadow: none;
            }
          }
        }
      }
    }

    @include when(error) {
      &:not(.is-active) {
        @include element(indicator) {
          background-color: $color-accent;
          animation: step-alert 1s ease-out infinite;
        }
      }

      @include element(circle) {
        background-color: transparent;
      }
    }
  }

  @include element(line) {
    $line-height: 4px;

    flex: 1;
    height: $line-height;
    overflow: hidden;
    background-color: $color-lighter-primary;
    contain: layout paint size;

    @include pseudo-class(before) {
      display: block;
      width: 100%;
      height: $line-height;
      content: '';
      background-color: $color-dark-primary;
      transition: 0.2s $perfect-timing;
      will-change: transform;
      transform: tx(-100%);
    }
  }

  @include element(label) {
    max-width: 180px;
    margin-bottom: 8px;
    font-size: 12px;
    color: var(--label-color);
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;

    @include perfect-transition;
  }

  @include element(circle background indicator) {
    contain: layout size;
  }

  @include element(circle) {
    @include circle(24px, $color-lighter-primary);

    position: relative;
    display: flex;
  }

  @include element(background) {
    @include perfect-transition;
    @include circle(100%);

    position: absolute;
    opacity: 1;
    transition-property: opacity;
  }

  @include element(indicator) {
    @include perfect-transition;
    @include circle(16px, $color-white);

    display: flex;
    margin: auto;
    font-size: 12px;
    color: $color-white;
    opacity: 0;
    transform: scale(0.5);

    > .fp-icon {
      margin: auto;
    }
  }

  @include element(wrapper) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0.6);
  }

  20% {
    transform: scale(1.1);
  }

  40% {
    transform: scale(0.97);
  }

  60% {
    transform: scale(1.04);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes step-alert {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }

  40% {
    box-shadow: 0 0 0 5px rgba($color-accent, 0.5);
  }

  to {
    box-shadow: 0 0 0 10px transparent;
  }
}
