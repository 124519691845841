@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(form) {
  @include element(title) {
    margin-bottom: 20px;
    margin-left: 10px;
    font-family: $content-font-family;
    font-size: 24px;
    line-height: 29px;
    color: $color-dark-grey;

    @include custom-selector('+', text) {
      margin-top: -10px;
    }
  }

  @include element(label) {
    @extend %font-label;
  }

  @include element(text) {
    margin-bottom: 20px;
    margin-left: 10px;
    font-family: $content-font-family;
    font-size: 15px;
    line-height: 19px;
    color: $color-dark-grey;
  }

  @include element(tooltip) {
    color: $color-mid-primary;
    cursor: pointer;

    &:hover {
      color: $color-dark-primary;
    }
  }
}
