@import 'durations';

// ===
// Overrides
// ===

.fa-spin {
  animation-duration: $duration-animation-spin;
}

// ===
// Vue transitions
// ===

@mixin normal-transition {
  &-enter-active,
  &-leave-active {
    @include perfect-transition;

    pointer-events: none;
    will-change: trnsform, opacity;
  }
}

.extend {
  @include normal-transition;

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
}

.zoom-out {
  @include normal-transition;

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale(0.4);
  }
}

.fade {
  @include normal-transition;

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.fade-quick {
  &-enter-active,
  &-leave-active {
    transition: 0.1s $perfect-timing !important;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.fade-slow {
  &-enter-active,
  &-leave-active {
    transition: 0.3s ease-out !important;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.fade-vertical-reverse {
  @include normal-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(0, -120px, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 120px, 0);
  }
}

.fade-vertical {
  @include normal-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(0, 120px, 0) !important;
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(0, -120px, 0) !important;
  }
}

.fade-right {
  @include normal-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(-120px, 0, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(120px, 0, 0);
  }
}

.fade-left {
  @include normal-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(120px, 0, 0) !important;
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(-120px, 0, 0) !important;
  }
}

.fade-skeleton {
  @include normal-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(0, 5px, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(0, -5px, 0);
  }
}

// View transitions

@mixin view-transition {
  &-enter-active,
  &-leave-active {
    pointer-events: none;
    transition: 0.15s $perfect-timing;
  }
}

.view-fade-vertical {
  @include view-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(0, -15px, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(0, 15px, 0);
  }
}

.view-fade-left {
  @include view-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(40px, 0, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(-40px, 0, 0);
  }
}

.view-fade-right {
  @include view-transition;

  &-enter {
    opacity: 0;
    transform: translate3d(-40px, 0, 0);
  }

  &-leave-to {
    opacity: 0;
    transform: translate3d(40px, 0, 0);
  }
}

// Side menu transitions

.side-menu-fade {
  &-enter-active,
  &-leave-active {
    @include perfect-transition;

    pointer-events: none;
    transition-duration: 500ms;
    will-change: transform, opacity;
  }

  &-enter,
  &-leave-to {
    transform: translate3d(-100%, 0, 0);
  }
}

// ===
// Chores
// ===

@keyframes scroll-indicator {
  20% {
    transform: translate3d(0, -15%, 0);
  }

  75% {
    transform: translate3d(0, 15%, 0);
  }
}

@keyframes scroll-indicator-horizontal {
  20% {
    transform: translate3d(-15%, 0, 0);
  }

  75% {
    transform: translate3d(15%, 0, 0);
  }
}
