@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';















@import '@scss';
.userIcon {
  font-size: 11em;
}
