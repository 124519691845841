@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(chip) {
  display: inline-block;

  > .v-chip.v-chip {
    background-color: var(--background-color);

    &.is-circle {
      width: 1em;
      height: 1em;
      padding: 0;
      margin-top: -1px;
      border-radius: 50%;
    }
  }

  .v-chip__content {
    color: var(--text-color);
  }

  @include modifier(primary) {
    --background-color: #{$color-primary};

    > .v-chip.v-chip {
      background-image: $gradient-primary;
    }
  }

  @include modifier(accent) {
    --text-color: #{$color-white};
    --background-color: #{rgba($color-accent, 0.9)};
  }

  @include modifier(info) {
    --text-color: #{$color-mid-grey};
    --background-color: #{rgba($color-mid-grey, 0.2)};
  }

  @include modifier(warning) {
    --text-color: #{rgba($color-black, 0.9)};
    --background-color: #{rgba($color-warning, 0.8)};
  }

  @include modifier(error) {
    --text-color: #{$color-white};
    --background-color: #{rgba($color-error, 0.9)};
  }

  @include modifier(success) {
    --text-color: #{rgba($color-white, 0.8)};
    --background-color: #{rgba($color-success, 0.99)};
  }

  @include modifier(disabled) {
    --background-color: #{$color-disabled};
  }

  @include modifier(inactive disbaled) {
    --text-color: #{$color-white};
    --background-color: #{$color-mid-grey};
  }

  @include modifier(primary) {
    --text-color: #{$color-white};
  }

  @include modifier(light) {
    --text-color: #{$color-dark};
    --background-color: #{rgba($color-white, 1)};
  }

  @include modifier(dark) {
    --text-color: #{$color-white};
    --background-color: #{rgba($color-dark, 1)};
  }
}
