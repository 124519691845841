@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(input) {
  @extend %vuetify-input-restyle;

  position: relative;
  outline: none;

  @include element(bottom-label) {
    position: absolute;
    bottom: 7px;
    left: 12px;
    font-size: 12px;
    color: #3a3a3a;
  }

  @include when(ice) {
    @extend %vuetify-input-restyle-ice;
  }

  @include modifier(text-right) {
    .v-text-field__slot > input {
      text-align: right;
    }
  }

  @include modifier(text-center) {
    .v-text-field__slot > input {
      text-align: center;
    }
  }
}
