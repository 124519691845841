/* stylelint-disable */
@import 'colors';
@import 'mixins';
@import 'durations';
@import 'fonts';
@import 'sizes';
@import 'media-queries';
@import 'layers';
@import 'typography';
@import 'materials';
@import 'placeholders';

@import 'components/alert';

:export {
  globalColorPrimary: $color-primary;
  globalColorAccent: $color-accent;
  globalColorMidAccent: $color-mid-accent;
  globalColorDarkerAccent: $color-darker-accent;
  globalColorBlack: $color-black;
  globalColorDarkGrey: $color-dark-grey;
  globalColorDarkPrimary: $color-dark-primary;
  globalColorLighterPrimary: $color-lighter-primary;
  globalColorLightPrimary: $color-light-primary;
  globalColorMidPrimary: $color-mid-primary;
  globalColorLightGrey: $color-light-grey;
  globalColorMidGrey: $color-mid-grey;
  globalColorMidIce: $color-mid-ice;
  globalColorLightIce: $color-light-ice;
  globalColorDarkIce: $color-dark-ice;
  globalColorWhite: $color-white;

  // states
  globalColorSuccess: $color-success;
  globalColorWarning: $color-warning;
  globalColorError: $color-error;
  globalColorInfo: $color-info;
  globalColorDisabled: $color-disabled;

  // gradients
  globalGradientPrimary: $gradient-primary;

  // fonts
  headingFontFamily: $heading-font-family;

  // Any values that need to be accessible from JavaScript
  // outside of a Vue component can be defined here, prefixed
  // with `global-` to avoid conflicts with classes. For
  // example:
  //
  // global-grid-padding: $size-grid-padding;
  //
  // Then in a JavaScript file, you can import this object
  // as you would normally with:
  //
  // import styles from '@styles'
  //
  // console.log(styles['global-grid-padding'])
  // global-color-primary-dark: $color-dark-primary;
}
