@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(feed-card) {
  margin-bottom: 8px;
  font-family: $heading-font-family;
  font-size: 14px;
  word-break: break-word;
  border-radius: 8px;

  .fp-card {
    background-color: transparent;
  }

  @include when(noIcon) {
    @include when(notSelected) {
      @include element(empty-circle) {
        border: 2px solid $color-primary;
      }
    }
  }

  @include element(cardContent) {
    padding: 16px;
    font-size: 14px;
    word-break: break-word;
  }

  @include modifier(big) {
    @include element(cardContent) {
      padding: 24px 30px;
      font-size: 14px;
      word-break: break-word;

      @include mq($until: sm) {
        padding: 24px 16px;
      }
    }
  }

  // Specific styling for usage on the onboarding flow - could be handy later
  @include modifier(radio) {
    @include perfect-transition;

    margin-bottom: 14px;
    box-shadow: 0 0 0 5px transparent;

    .fp-card {
      background-color: $color-white;
    }

    @include pseudo(focus) {
      outline: 0;
      box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12),
        0 0 0 1px $color-mid-primary, 0 0 0 5px $color-primary-glass;
    }

    @include pseudo(active) {
      outline: 0;
      box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12),
        0 0 0 1px $color-mid-primary, 0 0 0 5px $color-primary-glass;
    }

    @include element(title) {
      @extend %font-content-medium;
    }

    @include element(textWrapper) {
      display: flex;
      align-items: center;
    }

    @include element(cardContent) {
      padding: 20px 32px;
      // font-size: 14px;
      word-break: break-word;
    }
  }

  --icon-size: #{40px};

  @include when(selected) {
    @include perfect-transition;

    background-color: #{$color-mid-ice};
  }

  @include element(icon) {
    @include perfect-transition;
  }

  @include element(action) {
    @include perfect-transition;

    margin-left: 20px;
    font-size: 11px;
    font-weight: 600;
    color: $color-light-grey;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: $content-letter-spacing;
    cursor: pointer;

    &:first-of-type {
      margin-left: 0;
    }

    @include modifier(action) {
      color: $color-primary;
    }

    &:hover {
      @include perfect-transition;

      opacity: 0.7;
    }

    @include modifier(big) {
      font-size: 14px;
    }

    @include when(loading) {
      pointer-events: none;
      opacity: 0.2;
    }
  }

  @include element(title) {
    font-weight: $content-font-weight-bold;

    @include modifier(big) {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $color-dark-grey;
    }
  }

  @include element(message) {
    @include modifier(big) {
      font-size: 16px;
      line-height: 24px;
      color: $color-dark-grey;
    }
  }

  @include element(date-time) {
    font-weight: normal;
    color: $color-light-grey;
    text-align: right;

    @include modifier(big) {
      min-width: 120px;
      font-size: 16px;
      line-height: 24px;
      color: $color-mid-grey;
    }

    @include modifier(compact) {
      min-width: 95px;
      padding-top: 5px;
      font-size: 11px;
      line-height: 1;
      color: $color-light-grey;
    }
  }

  @include element(last-col) {
    min-width: 95px;
  }

  @include element(checkbox) {
    @include perfect-transition;

    position: relative;
    max-height: var(--icon-size);
  }

  @include element(empty-circle) {
    @include perfect-transition-el(background);

    position: absolute;
    top: 0;
    z-index: 1;
    width: var(--icon-size);
    height: var(--icon-size);
    background: transparent;
    border: 2px solid transparent;
    border-radius: 50%;
  }

  @include pseudo(hover) {
    @include when(selectable) {
      @include perfect-transition;

      cursor: pointer;
      background-color: $color-mid-ice;

      @include when(notSelected) {
        @include element(empty-circle) {
          @include perfect-transition-el(background);

          background: $color-mid-ice;
          border: 2px solid $color-primary;
        }
      }
    }
  }
}
