@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
// @import '~watson-scss';

// .root {
//   word-break: break-word;
//   h4 {
//     // @include ellipsis();
//   }
// }

.closeBtn {
  float: right;
  color: $color-mid-grey;
}
