@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.balance {
  padding-right: 10px;
  padding-left: 5px;
  font-size: 21px;

  @include mq($until: mobile) {
    font-size: 18px;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  font-size: 12px;
  color: $color-white;

  @include mq($until: mobile) {
    height: 40px;
    font-size: 12px;
  }

  &.left {
    padding: 0 20px 0 30px;
    background: var(--header-button-left-background);
    border-radius: 100px 0 0 100px;

    &.round {
      border-radius: 100px;
    }

    @include mq($until: mobile) {
      padding: 0 10px 0 20px;
    }
  }

  &.right {
    padding: 0 40px 0 20px;
    background: var(--header-button-right-background);
    border-radius: 0 100px 100px 0;

    @include mq($until: lg) {
      padding: 0 20px 0 20px;

      strong {
        display: none;
      }
    }
  }

  &:focus,
  &:active {
    background: var(--header-button-active-background);
    outline: none;
  }

  > .iconCircle {
    @include circle(24px, $color-white-glass);

    position: absolute;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    @include mq($until: lg) {
      color: $color-primary;
      background: linear-gradient(180deg, #f6f7fc 0%, #e3e8f4 100%);
    }
  }
}
