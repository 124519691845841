@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



































@import '@scss';

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}
