@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































@import '@scss';
@import '~watson-scss';

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  background: linear-gradient(47.39deg, #0070f3 0%, #57a3fc 99.6%);
}

.inner {
  @extend %center-content;

  padding-top: 24px;
  padding-bottom: 24px;

  @include mq($from: md) {
    display: flex;
    justify-content: space-between;
  }

  @include mq($until: md) {
    padding: 30px 20px 30px 20px;
  }
}

.logo {
  height: 24px;
}

.links {
  @include mq($from: md) {
    display: flex;
    align-items: center;
  }
}

.link {
  color: $color-white !important;
  text-decoration: none;

  @extend %font-footer;

  @include perfect-transition;

  svg {
    margin-right: 4px;
    color: rgba($color-mid-grey, 0.5) !important;
  }

  @include mq($from: md) {
    & + & {
      margin-left: 25px;
    }
  }

  @include mq($until: md) {
    &:first-child {
      padding-top: 20px;
    }

    display: block;
  }
}
