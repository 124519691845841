@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(pass-input) {
  --color-rule: #{$color-dark-grey};

  @include element(rules) {
    display: none;
    padding-bottom: 15px;
    list-style: none;
    opacity: 0;

    @include emphasize {
      padding-left: 15px;
    }
  }

  @include element(icon) {
    margin-right: 10px;
  }

  @include element(rule) {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-rule);

    @include when(done) {
      --color-rule: #{$color-success};
    }
  }

  @include when(showRules) {
    @include element(rules) {
      display: block;
      opacity: 1;
    }
  }
}
