.itemWrap {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.xAndAmount {
  position: relative;
}

.vCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.intervalText {
  font-size: 18px;
  font-weight: bold;
  color: $color-dark-grey;
  text-align: center;
}

.impressionURL {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.deleteBtn {
  position: absolute;
  top: 12px;
  left: 0;
  transform: translateX(-115%);
}

.questionBtn {
  color: $color-mid-primary;
  cursor: pointer;

  &:hover {
    color: $color-dark-primary;
  }
}
