// HIGHLIGHTS
%highlight-current-hover-component {
  border-color: $color-mid-primary;
  box-shadow: 0 0 0 1px $color-mid-primary;
}

%highlight-current-focus-component {
  border-color: $color-mid-primary;
  box-shadow: 0 0 0 1px $color-mid-primary, 0 0 0 5px $color-primary-glass;
}

%errorify-current-component {
  --current-state-color: #{$color-error};
  --current-state-color-light: #{$color-error-glass};

  color: $color-error;
  background-color: rgba($color-error, 0.03);
  border-color: $color-error;
  box-shadow: 0 0 0 1.5px $color-error, 0 0 0 5px $color-error-glass;
}

%successify-current-component {
  --current-state-color: #{$color-success};
  --current-state-success-light: #{$color-success-glass};

  color: $color-success;
  background-color: rgba($color-success, 0.03);
  border-color: $color-success;
  box-shadow: 0 0 0 1.5px $color-success, 0 0 0 5px $color-success-glass;
}

%vuetify-input-restyle {
  > .v-text-field.v-text-field--outlined {
    fieldset {
      background: $color-white;
      border-width: 2px;
      border-radius: 8px;
    }

    &:not(.v-input--has-state) {
      fieldset {
        border-color: $color-dark-ice;
      }

      .v-label {
        color: $color-mid-grey;
      }
    }

    &.v-input--is-disabled {
      &:hover {
        cursor: not-allowed;
      }

      fieldset {
        background: transparent;
        border-color: $color-disabled;
      }

      .v-label {
        color: $color-light-grey;
      }
    }

    &.v-input--is-focused,
    &.v-input--is-label-active {
      &:not(.v-input--has-state):not(.v-input--is-disabled) {
        fieldset {
          background: $color-white;
          // border-color: $color-mid-primary;
        }

        // .v-label {
        //   color: $color-mid-primary;
        // }
      }
    }

    &.v-input--is-focused:not(.v-input--has-state) {
      .v-input__control > .v-input__slot fieldset {
        border-color: $color-mid-primary;
      }

      .v-label {
        color: $color-mid-primary;
      }
    }

    &:not(.v-input--is-focused):not(.v-input--has-state) {
      .v-input__control > .v-input__slot:hover fieldset {
        border-color: $color-mid-primary;
      }

      .v-input__control > .v-input__slot:hover .v-label--active {
        color: $color-mid-primary;
      }
    }

    .theme--light.v-input:focus {
      outline: none;
    }

    .v-input__control:focus {
      outline: none;
    }

    .v-input__slot:focus {
      outline: none;
    }

    .v-input__slot fieldset:focus {
      outline: none;
    }

    .v-text-field__slot:focus {
      outline: none;
    }

    .v-input__append-inner {
      margin-top: 18px;
    }

    .v-input__icon > .v-icon {
      position: relative;
      z-index: 2;
      font-size: 24px;
    }

    .v-input__append-inner > .v-input__icon > .v-icon {
      font-size: 18px;
    }

    .v-input__icon--prepend-inner > .v-icon {
      font-size: 18px;
    }

    .v-input__icon--append-outer > .v-icon:hover {
      color: $color-mid-primary;
      cursor: pointer;
    }
  }
}

%vuetify-input-restyle-ice {
  $ice-input-background-color: rgba($color-dark-ice, 0.1);

  .primary--text {
    color: $color-white !important;
  }

  > .v-text-field.v-text-field--outlined {
    fieldset {
      background-color: rgba($color-dark-ice, 0.2);
      border-color: transparent;
    }

    .v-label {
      color: $color-white;
    }

    &.v-input--is-disabled {
      &:hover {
        cursor: not-allowed;
      }

      fieldset {
        background-color: $ice-input-background-color;
        border: none;
      }

      .v-label {
        color: rgba($color-white, 0.3);
      }
    }

    &.v-input--is-focused,
    &.v-input--is-label-active {
      &:not(.v-input--has-state):not(.v-input--is-disabled) {
        fieldset {
          background-color: $ice-input-background-color;
          border-color: $color-white;
        }

        .v-text-field__slot {
          input {
            color: $color-white;
          }
        }

        .v-label {
          color: $color-white;
        }
      }
    }

    .v-input__icon > .v-icon {
      color: $color-white;
    }

    &:not(.v-input--is-focused):not(.v-input--has-state) {
      .v-input__slot:hover fieldset {
        background-color: $ice-input-background-color;
        border-color: $color-white;
      }

      .v-input__control > .v-input__slot:hover .v-label {
        color: $color-white;
      }
    }
  }
}

// APPEARANCES
%input-appearance-normal {
  @extend %font-input;

  @include perfect-transition;

  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: text;
  background-color: $color-white;
  border: 1px solid $color-disabled;
  border-radius: 100px;
}
