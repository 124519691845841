@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(toggle) {
  --height: #{$size-input-height-normal};
  --item-width: 150px;
  --font-size: 16px;
  --item-height: calc(var(--height) - 2 * var(--vertical-margin));
  --border-radius: 10px;
  --track-bacground: #{$color-light-ice};
  --slider-background: #{$gradient-primary};
  --slider-extra-height: 3px;
  --state-color: #{$color-mid-primary};

  display: inline-flex;
  height: var(--height);

  @include mq($until: mobile) {
    width: 100%;
  }

  // ===
  // Elements
  // ===
  @include element(track) {
    position: relative;
    display: flex;
    width: 100%;
    padding: 0;
    margin: var(--slider-extra-height) 0;
    background-color: var(--track-bacground);
    border-radius: var(--border-radius);
  }

  @include element(slider) {
    @include perfect-transition;

    position: absolute;
    top: calc(-1 * var(--slider-extra-height));
    bottom: calc(-1 * var(--slider-extra-height));
    width: var(--item-width);
    background: var(--slider-background);
    border-radius: inherit;
    box-shadow: 0 4px 13px -3px rgba(0, 40, 143, 0.52);
  }

  @include element(item) {
    @include perfect-transition;

    @include pseudo-class(focus) {
      color: var(--state-color);
      border-radius: inherit;
      outline: none;
    }

    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--item-width);
    max-width: var(--item-width);
    height: var(--item-height);
    padding: 0 15px;
    overflow: hidden;
    font-size: var(--font-size);
    font-weight: 600;
    color: $color-dark-grey;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    @include when(active) {
      color: $color-white;
      cursor: default;
    }

    > span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @include modifier(small) {
    --font-size: 14px;
    --height: #{$size-input-height-small};
  }

  @include modifier(accent) {
    --track-bacground: #{$color-white};
    --slider-background: #{$gradient-accent};
    --state-color: #{$color-accent};

    @include element(slider) {
      box-shadow: 0 3px 11px -2px rgba(143, 21, 0, 0.32);
    }

    @include element(item) {
      @include pseudo-class(focus) {
        border-color: $color-accent;
      }
    }
  }

  @include modifier(accent) {
    --slider-background: #{$gradient-accent};
  }

  // ===
  // States
  // ===

  @include when(disabled) {
    --track-bacground: #{$color-light-ice};
    --slider-background: #{$color-dark-ice};
    --text-color: #{$color-disabled};

    pointer-events: none;
    cursor: not-allowed;

    @include element(slider) {
      box-shadow: none;
    }

    @include element(item) {
      color: var(--text-color);
    }
  }

  @include when(fill) {
    width: 100%;
  }
}
