@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.container {
  // Width 400px include padding
  width: 100%;
  max-width: 360px;

  @include mq($until: mobile) {
    max-width: 100%;
  }
}

.list {
  padding: 10px 0 15px;
}

.divide {
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: rgba($color-black, 0.08);
}

.empty {
  padding: 40px 0 60px;
  color: $color-mid-grey;
  text-align: center;
}
