$layer-negative-z-index: -1;
$layer-page-z-index: 1;
$layer-dropdown-z-index: 2;
$layer-modal-z-index: 3;
$layer-popover-z-index: 4;
$layer-tooltip-z-index: 5;
$layer-max-z-index: 2147483638;

%overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($color-light-ice, 0.75);
}

%center-content {
  width: 100%;
  min-width: $size-content-width-min;
  max-width: $size-content-width-max;
  padding: 0 50px;

  @include mq($until: lg) {
    padding: 0 30px;
  }

  @include mq($until: mobile) {
    min-width: $size-content-width-min-small;
    padding: 0 3vw;
  }
}
