// Card Border Mixin
//
// Usage
// Without Hover Effect
// @include card-border();
// With Hover Effect
// @include card-border('true');

@mixin card-border($hover: 'false') {
  padding: 35px 25px 15px 30px;
  background-color: $color-white;
  border-radius: 9px;
  box-shadow: -1px 14px 26px -7px rgba(0, 0, 0, 0.19);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  @if $hover == 'true' {
    &:hover {
      box-shadow: -1px 14px 26px -7px rgba(0, 0, 0, 0.19),
        0 0 0 3px $color-primary, 0 0 0 6px rgba(0, 71, 255, 0.1);
    }
  }
}
