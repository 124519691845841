@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.fieldBox {
  min-height: 90px;
}

.fieldLabel {
  font-family: $heading-font-family;
  font-size: 15px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.fieldDisplayValue {
  padding-bottom: 42px;
  font-family: $heading-font-family;
  font-size: 16px;
  line-height: 24px;
  // @include ellipsis();
  word-break: break-word;
}
