// LAYOUT COLORS
$color-primary: #0c4ff5;
// BLUE +3
$color-lighter-primary: #8bbffc;
// BULE +2
$color-light-primary: #57a3fc;
// BULE +1
$color-mid-primary: #0070f3;
// BLUE -1
$color-dark-primary: #0238be;
// BLUE -2
$color-darker-primary: #002788;

$color-accent: #ec5a29;
// ORANGE +1
$color-mid-accent: #ff784a;
$color-darker-accent: #ba2f00;

// GRAYSCALE
$color-dark: #111;
$color-dark-grey: #666;
$color-light-grey: #bababa;

$color-black: #2e2d38;
$color-black-secondary: #232323;
$color-black-pale: #101010;
$color-black-real: #000;

$color-mine-shaft: #3a3a3a;

$color-mid-grey: #8e8e8c;
$color-light-grey: #bdbdbd;

$color-dark-ice: #e3e8f4;
$color-mid-ice: #eef2fd;
$color-light-ice: #f6f7fc;

$color-white: #fff;

// STATES
$color-success: #6bd47c;
$color-warning: #fbe44d;
$color-error: #ec4f4d;
$color-disabled: #e5e5e5;
$color-info: $color-mid-grey;

// GLASSES
// $color-lighter-primary-glass: rgba($color-lighter-primary, 0.05);

$color-primary-glass: rgba($color-primary, 0.2);
$color-mid-primary-glass: rgba($color-mid-primary, 0.2);
$color-dark-primary-glass: rgba($color-dark-primary, 0.9);

$color-accent-glass: rgba($color-accent, 0.2);
$color-error-glass: rgba($color-error, 0.2);
$color-warning-glass: rgba($color-warning, 0.4);
$color-success-glass: rgba($color-success, 0.3);

$color-black-glass: rgba($color-black, 0.2);
$color-white-glass: rgba($color-white, 0.2);

// TEXTS
$color-text-default: $color-black;
$color-text-heading: $color-black;
$color-text-disabled: $color-mid-grey;

// FORMS
$color-form-hints: rgba(0, 0, 0, 0.54); // same as v-message hints

// GRADIENTS
$gradient-primary: linear-gradient(180deg, #57a3fc 0%, #0070f3 100%);
$gradient-primary-diagonal: linear-gradient(
  58.42deg,
  #0070f3 0%,
  #57a3fc 99.6%
);
$gradient-error: linear-gradient(180deg, #ff8885 0%, #f84642 100%);

$gradient-accent: linear-gradient(180deg, #ff784a 0%, #ec5a29 100%);
$gradient-ice: linear-gradient(180deg, #f6f7fc 0%, #e3e8f4 100%);
