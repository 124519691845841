@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.line {
  border-top: 1px solid $color-dark-ice;
}

.title {
  font-family: $heading-font-family;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: $color-dark-grey;
  text-transform: uppercase;
  letter-spacing: $content-letter-spacing * 2;
}
