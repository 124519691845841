@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.logo {
  position: fixed;
  top: 30px;
  left: 30px;
  display: block;
  max-width: 180px;
  margin-bottom: 40px;
}

.logoColour {
  @extend .logo;

  position: absolute;

  @include mq($from: md) {
    display: none;
  }
}

.logoWhite {
  @extend .logo;

  display: none;

  @include mq($from: md) {
    display: block;
  }
}

.side {
  @extend %full-height;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 50%;
  background-image: url('../../assets/images/background-login.png');
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;

  .tagline {
    font-weight: normal;
    line-height: 1.33;
    color: $color-white;
    letter-spacing: 0.2px;

    @extend %typography-xlarge;
  }
}

.sideInner {
  max-width: 564px;
  margin: 20% auto;
}

.form {
  @extend %full-height;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 30px;
  margin: 0 auto;

  > .formInner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > section {
      position: relative;
      width: 100%;
      max-width: 500px;
    }
  }

  @include mq($until: md, $and: '(max-height: 900px)') {
    padding-top: 90px;
  }
}

.formTitle {
  @extend %typography-xlarge;

  font-size: 2rem;
  color: $color-text-default;
}

.errorList {
  @extend %typography-small;

  position: absolute;
  bottom: -60px;
  width: 100%;
  color: $color-error;
  text-align: center;
}

// default class for first column
.first {
  background-color: $color-dark-primary;

  @include mq($until: md) {
    display: none !important;
  }
}

p.tip {
  margin: 10px 0 20px;
  line-height: 1.2;
  color: $color-mid-grey;
}
