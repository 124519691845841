@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































@import '@scss';

.unset {
  font-style: italic;
  font-weight: normal;
  color: $color-mid-grey;
  opacity: 0.3;
}

.card {
  @include card-border();

  position: relative;
  margin-bottom: 30px;

  &.small {
    padding: 10px 25px 15px 30px;
  }

  .title {
    display: flex;
    align-items: center;
    height: 25px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    color: $color-dark-primary;
    text-transform: uppercase;

    .titleDot {
      width: 13px;
      min-width: 13px;
      height: 13px;
      margin-right: 8px;
      background: $color-primary;
      border-radius: 50%;
    }
  }

  .content {
    margin-bottom: 30px;
  }
}

.cta {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  padding: 0 25px 15px 30px;
  margin-top: -57px; // Use 57 since we want it to keep the same positioning as the previous css

  > button {
    max-width: 100%;
    margin: 0 !important;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
