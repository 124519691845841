@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.body {
  position: relative;
  background-color: $color-mid-ice;

  > :global(.close-button) {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  :global(.v-image__image) {
    background-color: $color-mid-ice;
  }
}

.video {
  max-width: 100vw;
  max-height: 80vh;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px 25px 20px;
}

.fileName {
  max-width: 95%;
  font-size: 18px;
  font-weight: bold;
}

.progressBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
