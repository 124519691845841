$system-default-font-family: 'Roboto', helvetica, arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', blinkmacsystemfont;
$default-font-size: 16px;

$heading-font-family: 'Poppins', sans-serif;
$heading-font-weight-heavy: 700;
$heading-font-weight-bold: 600;
$heading-font-weight-medium: 500;
$heading-font-weight: 600;

$content-font-family: $system-default-font-family;
$content-font-family-medium: 'Roboto Medium', $system-default-font-family;

$content-font-weight: 400;
$content-font-weight-medium: 500;
$content-font-weight-bold: 700;

$content-letter-spacing: 0.04em;

%font-heading {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $color-text-heading;
}

%font-content-bold {
  font-family: $content-font-family;
  font-size: $default-font-size;
  font-weight: $content-font-weight-bold;
}

%font-content-medium {
  font-family: $content-font-family-medium;
  font-size: $default-font-size;
  font-weight: $content-font-weight-medium;
}

%font-content {
  font-family: $content-font-family;
  font-size: $default-font-size;
  font-weight: $content-font-weight;
  color: $color-text-default;
}

%font-button {
  font-size: 14px;
  font-weight: $content-font-weight-bold;
}

%font-dialog {
  font-family: $content-font-family;
  font-size: $default-font-size;
  font-weight: $content-font-weight;
  color: $color-dark-grey;
  letter-spacing: 0.1px;
}

%font-label {
  display: block;
  padding-left: 10px;
  margin-bottom: 10.6px;
  font-size: $default-font-size;
  font-weight: bold;
  color: $color-dark-grey;
}

%font-input {
  font-size: 1rem;
  font-weight: 400;
}

%font-input-label {
  font-size: 14px;
  font-weight: $content-font-weight-bold;
  color: $color-black;
}

%font-stepper {
  font-size: $default-font-size;
  font-weight: $content-font-weight-bold;
  letter-spacing: 0.1px;
}

%font-menus {
  font-family: $heading-font-family;
  font-size: $default-font-size;
  font-weight: $heading-font-weight;
  text-decoration: none;
  letter-spacing: -0.2px;
}

%font-toggle {
  font-size: 15px;
  font-weight: $content-font-weight-bold;
}

%font-toaster {
  font-family: $content-font-family !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: $default-font-size !important;
  letter-spacing: 0.1px !important;
}

%font-footer {
  font-family: $content-font-family;
  font-size: 14px;
  line-height: 21px;
}

%font-hint {
  font-size: 12px;
  line-height: 14px;
  color: $color-black;

  a {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
  }
}

%font-label-hint {
  display: block;
  width: 100%;
  padding-left: 11px;
  margin-top: -8px;
  margin-bottom: 10px;
  font-size: 12px;
  color: $color-form-hints;
}
