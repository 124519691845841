@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

$content-min-max-width: 410px;

.content {
  min-width: $content-min-max-width;
  max-width: $content-min-max-width;
  font-family: $heading-font-family;
  color: $color-dark-grey;
}

.iconBox {
  color: $color-dark-grey;
  text-align: center;
}

.icon {
  font-size: 26px;
}

.header {
  color: $color-dark-grey;
}

.itemBox {
  padding: 25px 16px;
}

.noItems {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.itemSkele {
  min-width: $content-min-max-width;
}

.footerButton {
  font-weight: 600;
  line-height: 24px;
  color: $color-mid-grey !important;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: $content-letter-spacing;

  .label {
    padding-left: 8px;
    &:hover {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}
