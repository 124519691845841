@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





































@import '@scss';

.container {
  position: fixed;
  left: 50vw;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 10px;
  font-size: 12px;
  color: $color-white;
  white-space: nowrap;
  background-color: $color-dark;
  border-radius: 6px;
  box-shadow: 0 3px 13px -1px rgba($color-dark, 0.12);
  opacity: 0.7;
  transform: translateX(-50%);

  :global {
    .fp-icon {
      margin-left: 7px;
    }
  }

  &.up {
    top: #{$size-header-height - 10};

    :global {
      .fp-icon {
        animation: scroll-indicator 1s linear infinite;
      }
    }
  }

  &.down {
    bottom: 110px;

    :global {
      .fp-icon {
        animation: scroll-indicator 1s linear infinite reverse;
      }
    }
  }
}
