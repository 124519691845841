@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';













































































































































































































@import '~watson-scss';
@import '@scss';

$header-action-x-pad: 10px;

.container {
  display: flex;
  justify-content: center;
  height: 75%;
  margin-left: auto;

  @include mq($until: mobile) {
    width: 100%;
    margin: 0;
  }
}

.headerAction {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 $header-action-x-pad;
  margin-left: 8px;
  font-size: inherit;
  color: var(--header-text-active-color);
  cursor: pointer;
  user-select: none;
  border-radius: 6px;

  @include mq($until: 1400px) {
    margin-left: 0;
  }

  @include mq($until: md) {
    padding: 5px;
    &:first-child {
      padding-left: $header-action-x-pad;
    }
  }

  @include pseudo-class(focus) {
    outline: none;
  }

  &.effect {
    @include perfect-transition;

    padding: 0 15px;

    @include mq($until: 1400px) {
      padding: 0 10px;
    }

    @include mq($until: mobile) {
      padding: 0;
    }

    @include pseudo-class(focus) {
      background-color: $color-white-glass;
    }
  }
}

.balance {
  padding-right: 10px;
  padding-left: 5px;
  font-size: 21px;

  @include mq($until: mobile) {
    font-size: 18px;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  font-size: 12px;
  color: $color-white;

  @include mq($until: mobile) {
    height: 40px;
    font-size: 12px;
  }

  &.left {
    padding: 0 20px 0 30px;
    background: var(--header-button-left-background);
    border-radius: 100px 0 0 100px;

    &.round {
      border-radius: 100px;
    }

    @include mq($until: mobile) {
      padding: 0 10px 0 20px;
    }
  }

  &.right {
    padding: 0 40px 0 20px;
    background: var(--header-button-right-background);
    border-radius: 0 100px 100px 0;

    @include mq($until: lg) {
      padding: 0 20px 0 20px;

      strong {
        display: none;
      }
    }
  }

  &:focus,
  &:active {
    background: var(--header-button-active-background);
    outline: none;
  }

  > .iconCircle {
    @include circle(24px, $color-white-glass);

    position: absolute;
    right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

    @include mq($until: lg) {
      color: $color-primary;
      background: linear-gradient(180deg, #f6f7fc 0%, #e3e8f4 100%);
    }
  }
}

.userName {
  @include ellipsis();

  min-width: 80px;
  max-width: 130px;
  margin: 0 8px;
  font-size: 1.12em;
  text-align: center;
}

.icon {
  color: inherit;
}
.userIcon {
  font-size: 2.5em;
}
.angleIcon {
  font-size: 1.25em;
}

.userName,
.angleIcon {
  @include mq($until: mobile) {
    display: none;
  }
}
