@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































@import '@scss';

$grid-padding-right: 24px;

.container {
  position: relative;
  flex-grow: 1;
  padding-right: $grid-padding-right;
  padding-bottom: 35px;
  padding-left: 45px;
}

.list {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  height: 100%;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0;
  list-style: none;
  border-spacing: 3px;
}

.weak,
.solid {
  display: table-cell;
  width: 12px;
  transition: height 0.6s;
}

.solid {
  margin-right: 2px;
  background-color: $color-primary-glass;
}

.weak {
  background-color: $color-accent-glass;
}

.xaxis,
.yaxis {
  position: absolute;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.yaxis {
  top: 0;
  bottom: 35px;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 45px;
}

.xaxis {
  right: 0;
  bottom: 17px;
  left: 45px;
  align-items: flex-start;
  justify-content: space-around;
  padding-right: $grid-padding-right;
}
