@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';













@import '@scss';

.container {
  flex-grow: 1;
}
