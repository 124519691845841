@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
























@import '@scss';

.container {
  display: flex;
  flex-grow: 1;
  height: 100%;

  &:global(.v-enter-active) {
    transition: opacity 1s;
  }

  &:global(.v-enter) {
    opacity: 0;
  }
}

.loadingIcon {
  @extend %typography-xxlarge;

  margin: auto;
}
