@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


































































































@import '@scss';

.selectBox {
  position: relative;
}

.removeCard {
  position: absolute;
  right: 0;
  bottom: 0;
}

.label {
  @extend %font-input-label;
}

.clickable {
  &:hover {
    color: $color-primary;
    cursor: pointer;
  }
}

.newCC {
  // sync margin with input label margins instead of mt helper
  margin-top: 10.6px;
}
