@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

$text-line-height: 21px;

.activator {
  position: relative;
  :global {
    .fp-icon {
      font-size: 26px;
    }

    .fp-icon-button {
      width: 50px;
      height: 50px;
    }
  }
}

.dot {
  position: absolute;
  top: 9px;
  right: 10px;
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: $color-error;
  border-radius: 50%;
}

.card {
  font-size: 14px;
  word-break: break-word;
}

.cardContent {
  padding: 24px 16px 15px 16px;
}

.cardTitle {
  font-weight: $content-font-weight-bold;
}

.cardAction {
  font-size: 11px;
  font-weight: 600;
  color: $color-light-grey;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: $content-letter-spacing;
  cursor: pointer;

  &:first-of-type {
    color: $color-primary;
  }

  &:hover {
    color: $color-primary;
  }
}

.cardTime {
  font-size: 11px;
  line-height: $text-line-height;
  color: $color-light-grey;
  text-align: right;
}

.footerButton {
  font-weight: 600;
  line-height: 24px;
  color: $color-mid-grey !important;
  text-transform: uppercase;
  letter-spacing: $content-letter-spacing;

  .label {
    padding-left: 8px;
    &:hover {
      color: $color-primary;
      cursor: pointer;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}
