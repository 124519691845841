@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';
@import '~watson-scss';

.titleWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 99%;
}

.title {
  @include ellipsis();

  display: block;
  flex: 1;

  &:hover {
    text-decoration: underline;
  }

  @include mq($until: sm) {
    font-size: 18px;
  }
}
