@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

.fp-date-time__input_box:focus {
  outline: none;
}

@include block(date-time) {
  position: relative;
  height: 450px;
  overflow: hidden;

  .v-picker {
    width: 100%;
    height: 100%;

    &__title__btn:not(.v-picker__title__btn--active) {
      font-weight: bold;
      opacity: 0.8;
    }
  }

  .v-picker--landscape {
    $title-width: 280px;

    .v-picker__title {
      width: $title-width;
      height: 100%;

      &.v-picker__title {
        border-radius: 0 0 0 0;
      }
    }

    .v-picker__body:not(.v-picker__body--no-title) {
      padding-top: 55px;
      margin-right: 70px;
      margin-left: 370px;
    }
  }

  .v-date-picker-table--date .v-btn {
    width: 40px;
    height: 40px;
  }

  @include element(time) {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding-bottom: 50px;
    padding-left: 30px;
  }

  @include element(time-input) {
    font-size: 40px;
    font-weight: 400;
    color: $color-white;

    &:focus {
      outline: none;
    }
  }

  @include element(footer) {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
  }
}
