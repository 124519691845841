@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



























































































































































































































































@import '~watson-scss';
@import '@scss';

.container {
  position: relative;
  z-index: 1;
  height: 100%;
}

.menus {
  align-items: center;
  margin: 0;
  margin-left: 15px;
  list-style: none;

  @include mq($from: lg) {
    height: 100%;
  }

  &:not(.isVertical) > li:not(.separator) {
    @include mq($from: lg) {
      height: 100%;
    }
  }

  > li:not(.separator) a {
    @extend %font-menus;

    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    color: var(--header-text-color);
    white-space: nowrap;

    > span {
      @include perfect-transition;

      border-bottom: 3px solid transparent;
    }

    &:hover,
    &:focus {
      color: var(--header-text-active-color);

      > span {
        @include perfect-transition(300ms);

        border-bottom-color: var(--header-text-active-color);
      }
    }

    &:focus {
      outline: none;

      > span {
        @include perfect-transition(250ms);

        border-bottom-color: var(--header-text-active-color);
      }
    }

    &:global(.is-active) {
      color: var(--header-text-active-color);

      > span {
        border-bottom-color: $color-accent;
      }
    }

    &.externalLink {
      color: var(--header-text-color);

      > svg {
        margin-left: 6px;
        font-size: 13px;
      }
    }
  }

  .separator {
    width: 2px;
    height: 30%;
    background-color: rgba($color-white, 0.3);
  }

  &.isVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
    margin-left: 25px;

    > li:not(.separator) {
      display: inline-block;
      width: 100%;
      color: $color-white;

      a {
        display: block;
        width: 100%;
        padding: 20px 15px;
      }
    }
  }
}

.sideMenuContainer {
  height: 100%;
}

.sideMenuButton {
  padding: 10px;
  margin-left: 15px;
  font-size: 13px;
  font-weight: 800;
  color: var(--header-text-active-color);
  white-space: nowrap;
  border: 1px solid var(--header-text-active-color);
  border-radius: 6px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  // hide the menu word when we hide the logo, to avoid confusing menu and logo icons
  @include mq($until: md) {
    padding: 10px 14px;
    margin-left: 0;
    font-size: 0;

    svg {
      font-size: initial;
    }
  }

  @include mq($until: mobile) {
    margin-left: 0;
  }
}

.sideMenuOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);

  @include mq($from: lg) {
    display: none;
  }
}

.closeSideMenu {
  position: absolute;
  top: 20px;
  right: 30px;
  color: $color-white;
}

.sideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50vw;
  min-width: 300px;
  background: linear-gradient(88.21deg, #0070f3 0%, #57a3fc 99.6%);

  @include mq($until: md) {
    width: 70vw;
  }

  > .logo {
    position: absolute;
    top: 20px;
    left: 45px;
    width: 60px;

    @include mq($until: lg) {
      top: 25px;
    }
  }

  @include mq($from: lg) {
    display: none;
  }
}
