@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

.toasted-container {
  top: 50px !important;
  z-index: $layer-max-z-index;
  justify-content: flex-start;
}

.toast {
  --circle-color: #{$color-white};
  --circle-icon-color: #{$color-primary};
  --shadow-color: #{rgba(0, 0, 0, 0.1)};
  --message-color: #{$color-black};
  --subtext-color: #{rgba(17, 17, 17, 0.5)};
  --toast-bg: #{$color-white};
  --icon-size: 10px;
  --max-width: 1000px;

  width: fit-content;
  min-width: 200px;
  max-width: var(--max-width) !important;
  color: var(--message-color) !important;
  background: var(--toast-bg) !important;
  border-radius: 8px !important;
  box-shadow: 0 1px 30px 0 var(--shadow-color) !important;

  @include emphasize {
    align-items: center;
    justify-content: flex-start;
    padding: 9px 20px 9px 12px;
  }

  @extend %font-toaster;

  &__icon {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    margin: 0 15px 0 0 !important;
    font-size: var(--icon-size) !important;
    color: var(--circle-icon-color);

    @include circle(30px, var(--circle-color));
  }

  &__content {
    flex-grow: 1;
    text-align: left;
  }

  &__message {
    @include ellipsis();

    max-width: calc(var(--max-width) - 80px);
    text-align: inherit;
    white-space: nowrap;
  }

  &__action {
    padding: 0 !important;
    margin: 0 0 0 auto !important;
    font-size: 20px !important;
    color: rgba($color-black, 0.25) !important;

    > i {
      margin: 0 !important;
      font-style: normal !important;
      transform: rotate(-45deg);
    }
  }

  &__subtext {
    display: block;
    font-size: 12px !important;
    font-weight: $content-font-weight !important;
    color: var(--subtext-color);
  }

  &--primary {
    --shadow-color: #{rgba(0, 45, 163, 0.3)};
    --message-color: #{$color-dark-grey};
    --subtext-color: #{$color-white};
    --toast-bg: #{$gradient-primary};
  }

  &--success {
    --circle-color: #{$color-success};
    --circle-icon-color: $color-white;
  }

  &--warning {
    --circle-color: #{$color-warning};
    --circle-icon-color: $color-dark-grey;

    .fp-icon {
      transform: txy(0.5px, -1px);
    }
  }

  &--error {
    --circle-color: #{$color-error};
    --circle-icon-color: #{rgba($color-text-heading, 0.4)};

    > i {
      --icon-size: 12px;
    }
  }

  &--info {
    --circle-color: #{$color-primary};
    --circle-icon-color: #{rgba(#fff, 0.83)};

    > i {
      --icon-size: 12px;
    }

    .fp-icon {
      transform: tx(1px);
    }
  }

  & + & {
    margin-top: 12px;
  }
}
