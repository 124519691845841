@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

%cell-text {
  font-family: $heading-font-family;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  // line-height: 18px;
}

@include block(table) {
  // --cell-min-height: 72px;
  // --row-min-height: 72px;

  --cell-min-height: 47px;
  --row-min-height: 47px;
  --thead-height: 72px;
  --row-gap: 0;
  --highlight-width: 1.5px;
  --border-color: #{$color-dark-ice};

  position: relative;
  font-family: $heading-font-family;
  line-height: 18px;

  @include mq($until: mobile) {
    --cell-min-height: 48px;
    --row-min-height: 48px;
    --thead-height: 48px;
  }

  // fp-table > v-data-table > table > row > tr, td > cell
  .v-data-table {
    &.v-data-table {
      background-color: transparent;

      td {
        height: unset;

        .v-chip__content {
          @extend %cell-text;
        }
      }

      // tbody tr td:not(.v-data-table__mobile-row),
      // thead tr:last-child th {
      //   border-bottom: 1px solid var(--border-color);
      // }

      thead tr th {
        border: none !important;
      }
    }

    table {
      max-width: 100%;
      table-layout: auto;
      border-spacing: 0 var(--row-gap);
      border-collapse: separate;

      @include mq($until: mobile) {
        display: block;
        table-layout: initial;

        > tbody {
          display: block;
        }
      }
    }
  }

  @include element(head) {
    @include when(hidden) {
      visibility: collapse;
    }

    @include mq($until: mobile) {
      display: none;
    }
  }

  @include element(row) {
    @include perfect-transition;

    background-color: transparent;
    border-color: var(--border-color);
    transition-property: box-shadow;

    &:not(.fp-table__row--expand-row) {
      @include pseudo-class(hover focus) {
        border-color: transparent;
        outline: none;

        > td {
          background-color: var(--rowHoverBackground) !important;

          @include mq($until: mobile) {
            background-color: transparent !important;
          }
        }
      }

      @include pseudo-class(hover) {
        background-color: transparent !important;

        @include mq($until: mobile) {
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    @include modifier(expand-row) {
      background-color: $color-white;

      @include pseudo-class(hover focus) {
        background-color: $color-white !important;
      }

      > td {
        padding: 16px 0 80px 0 !important;

        @include mq($from: mobile) {
          border-top: 2px solid $color-dark-ice;
        }
      }
    }

    @include when(loading) {
      cursor: progress;
      opacity: 0.3;
    }

    @include when(alignTop) {
      @include element(td) {
        padding-top: 5px;
        padding-bottom: 5px;
      }

      @include element(cell) {
        align-items: flex-start;
      }
    }

    @include when(expanded) {
      @include element(td) {
        @include mq($from: mobile) {
          border-bottom: 1px solid transparent !important;
        }
      }
    }

    @include mq($until: mobile) {
      display: flex;
      flex-direction: column;
      padding: 2vh 0;
      border-bottom: 1px solid var(--border-color);
    }
  }

  @include element(placeholder) {
    padding-top: var(--row-gap);
    padding-bottom: var(--row-gap);
  }

  @include element(skeleton) {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding-right: 20px;
    padding-left: 20px;
    margin: 0 var(--highlight-width) 0;
    overflow: hidden;
    border-radius: 6px;

    @include modifier(thead) {
      height: 72px;
      padding-right: 20px;
      background: transparent;
      border: none;
    }
  }

  @include element(skeleton-actions) {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-left: auto;

    > .fp-skeleton + .fp-skeleton {
      margin-left: 10px;
    }
  }

  @include element(mobile-skeleton-card) {
    padding: 3vw 16px;
  }

  @include element(mobile-skeleton-row) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
  }

  @include element(th) {
    font-family: $heading-font-family;
    letter-spacing: $content-letter-spacing * 2;

    @include when(emphasize) {
      @include element(row) {
        cursor: pointer;
      }
    }

    @include emphasize {
      @include emphasize {
        box-sizing: border-box;
        height: var(--thead-height);
        // font-size: $size-table-font-cell;
        // font-weight: $heading-font-weight-medium;
        // line-height: 1;
        // color: $color-mid-grey;
        // text-transform: uppercase;
        // white-space: nowrap;
        // color: red;

        // version 2
        font-family: $heading-font-family;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 13px;

        /* Dark Grey */

        color: $color-dark-grey;
        text-transform: uppercase;

        /* identical to box height */

        letter-spacing: 0.04em;
      }
    }

    @include when(fixed) {
      --offset: 0;

      position: absolute;
    }

    @include modifier(fix-left) {
      left: var(--offset);
    }

    @include modifier(fix-right) {
      right: var(--offset);
    }

    @include when(sortable) {
      cursor: pointer;

      &:hover {
        color: $color-primary !important;
      }
    }

    @include when(sorting) {
      @include emphasize {
        color: $color-primary;
      }
    }

    @include modifier(right) {
      text-align: right !important;
    }

    @include modifier(center) {
      text-align: center !important;
    }

    @include modifier(checkbox) {
      @include emphasize {
        padding: 0;
        padding-left: 20px;
        text-align: center;
      }
    }
  }

  @include element(td) {
    @include perfect-transition;

    border-bottom: 2px solid $color-dark-ice !important;

    @include modifier(checkbox) {
      @include emphasize {
        padding: 0;
        padding-left: 20px;
        text-align: center;
      }

      @include mq($until: mobile) {
        display: none !important;
      }
    }

    @include when(fixed) {
      --offset: 0;

      @include mq($from: mobile) {
        position: absolute;
        background-color: $color-light-ice;
        border-left: 1px solid transparent;
      }
    }

    @include modifier(fix-left) {
      left: var(--offset);
    }

    @include modifier(fix-right) {
      right: var(--offset);
    }

    @include mq($until: mobile) {
      display: flex;
      align-items: center;
      width: 100% !important;
      min-height: 48px;
      border-bottom-width: 0 !important;

      &.is-operations {
        display: none;
      }
    }
  }

  @include element(scroll-btn) {
    @include circle(23px, rgba(0, 0, 0, 0.5));

    @extend %no-select;

    position: absolute;
    top: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    color: $color-white;
    cursor: pointer;
    box-shadow: 0 3px 5px -1px rgba($color-dark, 0.22);
    transform: tx(20%);

    > .fp-icon {
      animation: scroll-indicator-horizontal 1s linear infinite;
    }

    @include modifier(right) {
      transform: tx(-20%);

      > .fp-icon {
        animation: scroll-indicator-horizontal 1s linear infinite reverse;
      }
    }
  }

  @include element(total-amount) {
    font-size: 16px;
    font-weight: 600;
  }

  @include element(mobile-th) {
    display: none;
    font-family: $heading-font-family;
    font-size: $size-table-font-cell-mobile;
    font-weight: $heading-font-weight-medium;
    color: $color-mid-grey;

    @include mq($until: mobile) {
      display: block;
    }
  }

  @include element(cell-tooltip-activator) {
    @include ellipsis();
  }

  @include element(cell) {
    display: flex;
    align-items: center;
    min-height: var(--cell-min-height);
    // font-family: $content-font-family;
    // font-size: $size-table-font-cell;
    // font-weight: $content-font-weight-medium;

    @extend %cell-text;

    // width: 200px;

    color: $color-black;
    white-space: nowrap;

    @include mq($until: mobile) {
      font-size: $size-table-font-cell-mobile;
    }

    @include modifier(right) {
      justify-content: flex-end;
    }

    @include modifier(center) {
      justify-content: center;
    }

    // CELL TEXT STYLE START
    @include when(emphasize) {
      font-weight: 600;
    }

    .emphasize {
      color: $color-dark;
    }

    @include when(secondary) {
      color: $color-mid-grey;
    }

    .secondary.secondary {
      color: $color-mid-grey;
      background-color: transparent !important;
    }

    @include when(unimportant) {
      color: $color-mid-grey;
    }

    .unimportant,
    .is-unimportant {
      color: $color-mid-grey;
    }

    .info,
    .is-info {
      color: $color-info;
    }

    .disabled,
    .inactive,
    .is-disabled,
    .is-inactive {
      color: $color-disabled;
    }

    .primary,
    .is-primary {
      color: $color-mid-primary;
      background-color: transparent !important;
    }

    .error,
    .is-error {
      color: $color-error;
      background-color: transparent !important;
    }

    .success,
    .is-success {
      color: $color-success;
      background-color: transparent !important;
    }

    .warning,
    .is-warning {
      color: $color-warning;
      background-color: transparent !important;
    }
    // CELL TEXT STYLE END

    @include when(ellipsis) {
      @include ellipsis();

      display: flex;
      line-height: var(--cell-min-height);

      // &:hover {
      //   display: flex;
      //   line-height: 1.2;
      //   text-overflow: unset;
      //   word-break: break-all;
      //   white-space: normal;
      // }

      @include mq($until: mobile) {
        text-align: right;
      }
    }

    > .row-avatar {
      @include square(30px);

      display: inline-block;
      flex-shrink: 0;
      margin-right: 10px;
      font-weight: 600;
      line-height: 30px;
      color: $color-white;
      text-align: center;
      user-select: none;
      background: $color-mid-grey;
      border-radius: 5px;
    }

    @include when(selected) {
      > .row-avatar {
        background: $gradient-primary;
      }
    }

    .fp-icon-button__wrapper + .fp-icon-button__wrapper {
      margin: 0 10px;
    }

    @include mq($until: mobile) {
      position: relative;
      flex-grow: 1;
      height: 100%;
      min-height: initial;

      &:not(.is-leader) {
        justify-content: flex-end;
        padding-left: 3vw;
      }

      &.is-leader {
        margin-bottom: 3vw;
      }
    }

    .fp-button--text {
      font-family: $heading-font-family;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      color: $color-black;
      letter-spacing: 0.04em;
    }
  }

  @include element(link) {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;
    color: inherit !important;
    text-decoration: none;
    user-select: none;

    @include when(underline) {
      &:hover {
        text-decoration: underline $color-black;
      }
    }
  }

  @include element(sort) {
    cursor: pointer;
    outline: none;

    @include modifier(asc) {
      > .fp-icon {
        opacity: 1 !important;
        transform: rotateZ(180deg) !important;
      }
    }

    @include modifier(desc) {
      > .fp-icon {
        opacity: 1 !important;
        transform: rotateZ(0deg) !important;
      }
    }
  }

  @include element(footer) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 43px;

    @include mq($until: mobile) {
      justify-content: center;
      height: auto;
      padding-right: 3vw;
      padding-left: 3vw;
      margin-top: 5vw;
    }
  }

  @include element(footer-col) {
    display: flex;
    flex-basis: 33.33%;
    flex-shrink: 0;

    @include modifier(grow) {
      flex-grow: 1;
    }

    @include mq($until: mobile) {
      display: none;
    }
  }

  @include element(no-data) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px;
    color: $color-mid-grey;
  }

  @include when(selectable) {
    @include element(row) {
      @include pseudo-class(hover) {
        .fp-checkbox {
          > .fp-checkbox__indicator {
            border-color: $color-mid-primary;
          }
        }
      }
    }
  }

  @include when(rowClickable) {
    @include element(row) {
      cursor: pointer;
    }
  }

  @include when(showEdge) {
    @include element(td) {
      @include when(fixed) {
        @include when(edge) {
          border-left: 1px solid var(--border-color);
        }
      }
    }
  }

  @include when(borderFree) {
    --border-color: transparent;
  }

  .theme--light.v-data-table
    tbody
    tr:hover:not(.v-data-table__expanded__content) {
    background-color: transparent;
  }
}

@include block(simple-table) {
  --empty-row-box-height: 48px;

  $side-margin: 10px;

  position: relative;
  display: flex;
  justify-content: space-between;
  font-family: $content-font-family;
  font-size: 14px;
  line-height: 24px;
  color: $color-dark-grey;

  @include mq($until: mobile) {
    font-size: 16px;
  }

  @include element(emptyRowBox) {
    height: 48px;
    border-bottom: 1px solid #e3e8f4;
  }

  @include element(emptyRow) {
    padding-top: 12px;
  }

  @include element(column) {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    @include when(grow) {
      flex-grow: 1;
    }

    .fp-table__cell {
      max-width: 100%;
      min-height: 48px;
      padding: 0 10px;
      overflow: hidden;
      font-weight: normal;
      color: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      // border-bottom: 1px solid #e3e8f4;

      &:not(:last-child) {
        border-bottom: 1px solid $color-dark-ice;
      }
    }

    &:first-of-type {
      .fp-table__cell {
        padding-left: 0;
      }
    }

    &:last-of-type {
      @include element(heading) {
        justify-content: flex-end;
        padding-right: $side-margin;
      }

      .fp-table__cell {
        justify-content: flex-end;
        padding-right: $side-margin;
      }
    }
  }

  @include element(thead) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 48px;
    border-bottom: 1px solid $color-dark-ice;
  }

  @include element(heading) {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 12px;
    font-weight: 600;
    color: $color-mid-grey;
    text-transform: uppercase;
    user-select: none;

    @include when(sortable) {
      cursor: pointer;
    }

    @include when(sorting) {
      color: $color-primary;
    }

    > .fp-icon {
      margin-left: 3px;
    }
  }

  @include element(skeleton) {
    height: 48px;

    > .fp-skeleton {
      position: absolute;
      right: 30px;
      left: 0;
      opacity: 0.5;
    }
  }

  @include element(message) {
    @include when(light) {
      color: $color-white;
    }
  }

  @include when(light) {
    color: $color-white;

    @include element(thead) {
      display: none;
    }

    @include element(heading) {
      background-color: transparent;
    }
  }
}

.v-pagination {
  --button-size: 48px;

  display: inline-flex;
  width: auto;
  padding: 0;
  list-style: none;

  li {
    button,
    .v-pagination__more {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--button-size) !important;
      min-width: 0 !important;
      height: var(--button-size);
      padding: 0 !important;
      margin: 0;
      overflow: hidden;
      font-size: 16px;
      font-weight: 600;
      line-height: calc(var(--button-size) / 2);
      color: inherit;
      background-color: $color-dark-ice !important;
      border-radius: 0;
      outline: none;
      box-shadow: none;
    }

    > .v-pagination__navigation {
      border-radius: 50%;
    }

    &:nth-child(2):nth-last-child(2) {
      margin: 0 10px;
      > .v-pagination__item {
        border-radius: 50%;
      }
    }

    &:nth-child(2):not(:nth-last-child(2)) {
      button:not(.v-pagination__navigation) {
        margin-left: 10px;
        border-radius: 50% 0 0 50%;
      }
    }

    &:nth-last-child(2):not(:nth-child(2)) {
      button:not(.v-pagination__navigation) {
        margin-right: 10px;
        border-radius: 0 50% 50% 0;
      }
    }

    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-last-child(2)):not(
        :nth-last-child(1)) {
      background-color: $color-dark-ice !important;
    }

    .v-pagination__navigation {
      i {
        display: none;
      }

      &::before {
        width: 16px;
        height: 18px;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23111' d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }

      > .v-icon {
        display: none;
      }

      &--disabled::before {
        opacity: 0.4;
      }
    }

    .v-pagination__item--active {
      color: $color-light-ice;
      background-color: transparent !important;
      background-image: $gradient-primary !important;
      border-radius: 8px;
      box-shadow: 0 2px 16px rgba(5, 44, 142, 0.19);
    }

    .v-pagination__more {
      cursor: default;
    }

    &:first-child .v-pagination__navigation::before {
      transform: rotateZ(90deg);
    }

    &:last-child .v-pagination__navigation::before {
      transform: rotateZ(-90deg);
    }
  }
}
