@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.title {
  text-align: center;
  transform: translateY(-40px);
}
