@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.breadCrumb {
  display: flex;

  a {
    color: #000 !important;
    text-decoration: none;
  }
}

.breadCrumbItem {
  &:not(:first-child) {
    margin-left: 4px;
  }
}
