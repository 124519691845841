@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















@import '@scss';

.container {
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
}

.icon {
  margin-right: 8px;
}
