@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(upload) {
  --width: 100%;
  --padding-bottom: 0%;

  @include perfect-transition;

  position: relative;
  display: inline-flex;
  flex-shrink: 0;
  width: var(--width);
  max-width: 100%;
  cursor: pointer;
  background-color: $color-white;
  border: 1px solid $color-disabled;
  border-radius: 6px;

  @include pseudo-class(hover) {
    &:not(.is-uploading) {
      @include element(clear) {
        display: flex;
      }

      @include element(state-icon) {
        opacity: 0.1;
      }
    }

    &:not(.is-disabled):not(.is-readonly):not(.is-hasState):not(.is-filled) {
      box-shadow: 0 0 0 3px $color-primary;
    }
  }

  // ===
  // Elements
  // ===

  @include element(bearing-wall) {
    padding-bottom: var(--padding-bottom);
  }

  @include element(media file) {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @include element(label) {
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 6px;
    transition: height 0.2s $perfect-timing, background-color 0.2s ease-out;
    will-change: height;
  }

  @include element(label-text) {
    font-size: 30px;
    font-weight: 900;
  }

  @include element(media) {
    @include element(label-text) {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      line-height: 1;
      transform: ty(-30%);
    }
  }

  @include element(label-tip) {
    position: absolute;
    bottom: 9%;
    display: flex;
    flex-basis: 75px;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
    font-size: 13px;
    will-change: opacity, transform;
    transition: opacity, transform 0.2s $perfect-timing;

    .fp-icon {
      margin-left: 6px;
    }

    > small {
      font-size: 12px;
      color: $color-mid-grey;
    }
  }

  @include element(filename) {
    display: flex;
    align-items: center;
    padding: 10%;
    font-size: 25px;
    font-weight: bold;
    line-height: 24px;

    > .fp-icon {
      margin-right: 8px;
    }
  }

  @include element(preview) {
    @extend %full-fill;
  }

  @include element(preview-inner) {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    max-width: 100%;
    max-height: 100%;
    transform: txy(-50%, -50%);
  }

  @include element(mask progress) {
    @extend %full-fill;

    z-index: 3;
  }

  @include element(mask) {
    @include perfect-transition;

    background-color: rgba($color-black, 0.8);
    opacity: 0;

    @include when(lighter) {
      background-color: rgba($color-black, 0.6);
    }
  }

  @include element(progress) {
    background-color: rgba($color-primary, 0.9);
    transition: 0.1s linear;
    transform-origin: 0 50%;

    @include when(lighter) {
      background-color: rgba($color-primary, 0.6);
    }
  }

  @include element(state-icon clear spinner) {
    @include circle(24px);

    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }

  @include element(state-msg) {
    position: absolute;
    top: 17px;
    right: 50px;
    z-index: 4;
    font-size: 12px;
    font-weight: bold;
    color: $color-white;
  }

  @include element(spinner) {
    @include emphasize {
      position: absolute;
    }
  }

  @include element(state-icon) {
    @include perfect-transition;

    color: $color-white;
  }

  @include element(file) {
    @include element(state-icon) {
      top: 30px;
      right: 35px;
      font-size: 150px;
      color: rgba($color-white, 0.3);
    }
  }

  @include element(clear) {
    display: none;
    color: $color-black;
    background-color: $color-white;

    @include pseudo-class(hover) {
      @include perfect-transition;

      box-shadow: 0 0 0 4px rgba($color-white, 0.3);
    }
  }

  // ===
  // States
  // ===
  @include when(filled) {
    @include element(label) {
      color: $color-white;
    }

    @include element(state-icon) {
      background-color: $color-primary;
    }

    @include element(media) {
      @include element(label) {
        top: auto;
        height: 70px;
        background-color: rgba($color-primary, 0.9);

        &:not(:hover) {
          background-color: rgba($color-primary, 0.7);
          border-radius: 0 0 6px 6px;
        }
      }

      @include element(label-text) {
        transform: ty(0%);
      }

      @include element(label-tip) {
        color: inherit;
        opacity: 0;
        transform: tx(200px);

        > small {
          color: $color-white;
        }
      }
    }

    @include element(file) {
      @include element(label) {
        background-color: $color-primary;
      }

      @include element(label-tip) {
        > small {
          color: inherit;
        }
      }
    }

    &:hover {
      @include element(label) {
        height: 100%;
      }

      @include element(label-tip) {
        opacity: 1;
        transform: none;
      }
    }
  }

  @include when(dragHover) {
    box-shadow: 0 0 0 2px $color-primary, 0 0 0 5px $color-primary-glass;
  }

  @include when(uploading) {
    pointer-events: none;

    @include element(mask) {
      opacity: 1;
    }

    @include element(media) {
      @include element(label) {
        height: 0;
        color: $color-white;
        transform: none;
      }

      @include element(label-tip) {
        opacity: 0;
        transform: tx(200px);
      }
    }

    @include element(file) {
      @include element(label) {
        color: $color-white;
        background-color: transparent;
      }

      @include element(label-tip label-text) {
        display: none;
      }
    }
  }

  @include when(failed) {
    @extend %errorify-current-component;

    @include element(media) {
      @include element(state-icon) {
        background-color: $color-error;
      }
    }

    @include element(file) {
      @include element(label) {
        color: $color-white;
      }
    }

    @include element(state-icon) {
      background-color: $color-error;
    }

    @include element(media file) {
      @include element(label) {
        color: $color-white;

        &:not(:hover),
        &:hover {
          background-color: rgba($color-error, 0.95);
        }
      }

      @include element(label-tip) {
        color: inherit;

        > small {
          color: $color-white;
        }
      }
    }
  }

  @include when(success) {
    @include element(media) {
      @include element(state-icon) {
        background-color: $color-primary;
      }

      @include element(label) {
        background-color: rgba($color-primary, 0.8);
      }
    }
  }
}
