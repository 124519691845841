@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';



















































@import '@scss';

.root {
  width: 100%;
  max-width: 21px;
  height: 34.5px;
}
