@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';








































@import '@scss';

.row {
  display: flex;
  padding-top: 14px;
  padding-bottom: 14px;
  line-height: 21px;
  border-bottom: 1px solid #f7f8fb;

  .nameRow {
    color: $color-mid-grey;
  }

  .valueRow {
    display: flex;
    justify-content: flex-end;
    font-weight: 400;
    color: $color-dark;
  }
}
