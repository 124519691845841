@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





























































































































































































































































































@import '~watson-scss';
@import '@scss';

.roleChip {
  display: block;
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userInfo {
  display: flex;
  width: 100%;
  min-width: 240px;
  padding: 0 10px 10px;
  font-size: 16px;
}

.baseInfo {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: left;
}

.email {
  margin-bottom: 8px !important;
  font-size: 14px;
  line-height: 1.2;
}

.userName {
  max-width: 100%;
  min-height: 27px;
  margin-top: 7px;
  margin-bottom: 5px;
  font-weight: $content-font-weight-bold;
  line-height: 1.2;
}

.verification {
  display: inline-block;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: $content-font-weight-bold;
  line-height: 1;
  color: $color-white;
  background-color: $color-primary;
  border-radius: 20px;
}
