$perfect-timing: cubic-bezier(0.4, 0, 0.2, 1);

@mixin perfect-transition($dur: 200ms) {
  transition: $dur $perfect-timing;
}

@mixin perfect-transition-el($el, $dur: 200ms) {
  transition: $el $dur $perfect-timing;
}

// Responsive Content Padding
@mixin content-padding($pad-x: 40, $pad-y: 40) {
  padding: #{$pad-y}px #{$pad-x}px;

  @include mq($until: md) {
    padding: #{$pad-y / 2}px #{$pad-x / 2}px;
  }

  @include mq($until: sm) {
    padding: #{$pad-y / 3}px #{$pad-x / 4}px;
  }
}

@mixin card-form-padding($desk-y: 40, $desk-x: 100) {
  @include mq($from: sm) {
    padding: #{$desk-y}px #{$desk-x}px;
  }

  @include mq($until: md) {
    // pad must be the same as other card form in the app
    // use Your Organization page as a base reference.
    // 45 here to match Your Organization x padding
    padding: 25px 45px;
  }

  @include mq($until: sm) {
    padding: $card-px-sm;
  }
}
