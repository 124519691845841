// GRID
$size-grid-padding: 1.3rem;

// CONTENT
$size-content-width-max: 106.25rem;
$size-content-width-min: 25rem;
$size-content-width-min-small: 10rem;
$size-header-height: 74px;
$size-header-height-small: 60px;
$size-footer-height: 50px;
$size-gap-between-header-content: 5px;

// INPUTS
$size-input-height-normal: 56px;
$size-input-padding-normal: 20px;
$size-input-font-normal: 16px;
$size-input-padding-small: 18px;
$size-input-height-small: 46px;
$size-input-font-small: 14px;
$size-input-indicator: 24px;

// RADIOS
$size-radio-min-height-input: 56px;
$size-radio-min-height-normal: 90px;

// BUTTONS
$size-button-height-normal: 54px;
$size-button-padding-normal: 8px;
$size-button-icon-diameter-normal: 38px;

$size-button-height-medium: 44px;
$size-button-padding-medium: 8px;
$size-button-icon-diameter-medium: 31px;

$size-button-height-small: 38px;
$size-button-padding-small: 6px;
$size-button-icon-diameter-small: 28px;

$size-button-height-tiny: 30px;
$size-button-padding-tiny: 4px;
$size-button-icon-diameter-tiny: 24px;

$size-button-height-large: 60px;
$size-button-padding-large: 10px;
$size-button-icon-diameter-large: 40px;

// TABLES
$size-table-font-cell: 14px;
$size-table-font-cell-mobile: 16px;

%full-height {
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
}

%full-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

// PAGE
$size-page-for-form-heading-padding: 24px;

// CARDS
$card-px-sm: 15px;

// CORNERS
$size-card-border-radius: 8px;
