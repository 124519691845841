@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.card {
  @include mq($until: md) {
    padding: 30px;
  }

  @include mq($until: sm) {
    padding: 15px;
  }
}
