@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';





































































































@import '@scss';

.container {
  position: relative;
  background-color: $color-white;
}

.selectBox {
  position: absolute;
  top: 0;
  z-index: $layer-dropdown-z-index;
  width: 100%;
}

:global(.v-tabs-bar),
.selectBox {
  @include content-padding(70, 0);
}

.tabInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.tab:global(.v-tab--active) {
  &.isError {
    background-image: $gradient-accent;
  }
}

.tab:global(:not(.v-tab--active)) {
  &.isError {
    color: $color-accent !important;
  }
}

.errorIcon {
  margin-right: 5px;
  font-size: 20px;
}

:global(.v-tab) {
  height: 40px;
  font-weight: 600 !important;
}

:global(.v-tab--active) {
  color: $color-white !important;
  background: $gradient-primary;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
}

:global(.v-tabs-slider) {
  display: none;
}

:global(.v-slide-group__wrapper) {
  contain: unset;
  overflow: visible;
}

:global(.theme--light.v-tabs-items) {
  background-color: transparent;
}

:global(
    .v-application--is-ltr
      .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
      > .v-slide-group__wrapper
      > .v-tabs-bar__content
      > .v-tabs-slider-wrapper
      + .v-tab) {
  margin-left: 0;
}
