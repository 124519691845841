@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






















































































































































@import '@scss';

.headroom {
  --header-font-size: 16px;
  --header-background: transparent;
  --header-shadow: none;
  --header-text-color: #{rgba($color-white, 0.8)};
  --header-text-active-color: #{$color-white};
  --header-button-left-background: rgba(12, 79, 245, 0.29);
  --header-button-right-background: rgba(0, 112, 243, 0.8);
  --header-button-active-background: #{rgba($color-white, 0.2)};

  &[style*='fixed'] {
    --header-font-size: 16px;
    --header-background: #{$color-white};
    --header-shadow: 0 10px 30px -2px #{rgba($color-black, 0.12)};
    --header-text-color: #{rgba($color-black, 0.8)};
    --header-text-active-color: #{$color-black};
    --header-button-left-background: #{rgba(#0070f3, 0.8)};
    --header-button-right-background: #0070f3;
    --header-button-active-background: #{$color-dark-primary};
  }
}
