@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(popups) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  padding: 15px 20px;
  background-color: $color-white;
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(122, 122, 122, 0.12);

  @include when(fill) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
