@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


























@import '@scss';

.root {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 50%;
}

.img {
  display: inline;
  width: auto;
  height: 100%;
  margin: 0 auto;
}
