@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(dialog) {
  // ===
  // Elements
  // ===

  // background: $color-white;
  // border-radius: 8px;

  @include element(card) {
    @include card-border();

    position: relative;

    // make it the same padding as the guard dialog
    padding: 56px 45px 40px;
  }

  .v-card__title {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 30px;
    padding-left: 0;

    > .fp-icon-button {
      margin-left: auto;
    }
  }

  .v-card__text {
    padding: 0;
    padding-bottom: 32px;
  }

  .v-card__actions {
    padding: 0;
  }

  @include element(close) {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  @include element(title) {
    margin: auto;
    font-size: 24px;
    color: $color-black;
    word-break: break-word;
  }

  @include element(content) {
    @extend %font-dialog;

    min-height: 50px;
  }

  @include element(actions) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  @include element(loader) {
    padding-top: 71px;
    padding-bottom: 94px;
    text-align: center;

    .fp-dialog__spinner {
      width: 70px !important;
      height: 64px;
      color: $color-primary;
    }
  }

  // ===
  // States
  // ===

  @include when(loading) {
    cursor: wait;
  }

  @include when(error) {
    @include element(title) {
      color: $color-error;
    }
  }

  @include when(warning) {
    @include element(title) {
      color: $color-warning;
    }
  }

  @include when(centerActions) {
    @include element(actions) {
      justify-content: center;
    }
  }
}
