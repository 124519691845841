@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import '@scss';

.container {
  position: relative;
  width: 130px;
  padding: 5px 0 5px;
  color: $color-black;

  &:hover {
    cursor: pointer;

    > span {
      color: $color-black;
      text-decoration: underline;
    }
  }
}

.studentTag {
  display: block;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  text-indent: 1.4em;
}
