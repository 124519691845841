@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import 'common';

@include block(number) {
  --current-state-color: #{$color-primary};
  --current-state-color-light: #{$color-primary-glass};

  @extend %input-appearance-normal;

  height: $size-input-height-normal;
  margin-top: 10.6px;
  font-size: $size-input-font-normal;
  // ===
  // Pseudo Class
  // ===

  @include pseudo(hover) {
    &:not(.is-disabled):not(.is-readonly):not(.is-hasState) {
      @extend %highlight-current-hover-component;
    }
  }

  @include when(focus) {
    @extend %highlight-current-focus-component;

    @include element(inner) {
      opacity: 1;
    }

    @include element(display) {
      opacity: 0;
    }
  }

  // ===
  // Elements
  // ===

  @include element(prefix) {
    position: relative;
    z-index: 3;
    margin: 0;
    font-weight: bold;
    transform: tx(50px);
  }

  @include element(label) {
    @extend %font-input-label;
  }

  @include element(inner) {
    position: relative;
    right: 5%;
    left: 5%;
    z-index: 2;
    display: block;
    width: 90%;
    height: 100%;
    text-align: center;
    user-select: none;
    background-color: transparent;
    border: none;
    opacity: 0;

    @include pseudo(focus) {
      outline: 0;
    }
  }

  @include element(display) {
    @extend %full-fill;

    margin: 0;
    line-height: $size-input-height-normal;
    text-align: center;
    user-select: none;
  }

  @include element(btn) {
    @include square(32px);

    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: $color-white;
    background-color: var(--current-state-color);
    border-radius: 50%;

    @include modifier(minus) {
      left: 7px;
    }

    @include modifier(plus) {
      right: 7px;
    }

    @include pseudo(hover) {
      @include perfect-transition;

      cursor: pointer;

      @include pseudo-class(active) {
        box-shadow: 0 0 0 6px var(--current-state-color-light);
        transform: scale(0.95);
      }
    }
  }

  // ===
  // States
  // ===

  @include when(disabled) {
    color: $color-text-disabled;
    cursor: not-allowed;
    background-color: $color-mid-ice;

    @include element(btn) {
      pointer-events: none;
      background-color: $color-mid-grey;
    }

    @include element(inner) {
      cursor: not-allowed;
    }
  }

  @include when(readonly) {
    color: $color-mid-grey;
    cursor: default;

    @include element(btn) {
      pointer-events: none;
      background-color: $color-mid-grey;
    }

    @include element(inner) {
      cursor: default;
    }
  }

  @include when(error) {
    @extend %errorify-current-component;
  }

  @include when(success) {
    @extend %successify-current-component;
  }
}
