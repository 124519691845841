@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























@import '@scss';

.container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;

  &.spaceBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 75px;
}
